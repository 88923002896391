import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {createSlice} from "@reduxjs/toolkit";
import {UserData} from "../../api/staff/code";
import {getAllMedicareStaffMembers, getStaffMembersByAccessLevel} from "./thunks/thunks";

const initialState = createDefaultNullableStoreState<UserData[]>([]);

const staffListSlice = createSlice({
    name: "staffList",
    initialState,
    reducers: {
        clearStaffListStore(state) {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(getAllMedicareStaffMembers, builder);
        createBuildHandlers(getStaffMembersByAccessLevel, builder);
    }
});

export const {clearStaffListStore} = staffListSlice.actions;
export default staffListSlice.reducer;
