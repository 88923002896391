import {useRouteSetup} from "../../../components/Hooks/useRouteSetup";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {nullifyUserConfigStore} from "../../../store/userAbsenceConfig/userAbsenceConfigSlice";
import {nullifyStaffStore} from "../../../store/staff/staffSlice";
import {showErrorToast} from "../../../utils/toastUtils";
import {getUserAbsenceConfig} from "../../../store/userAbsenceConfig/thunks/thunks";
import {fetchStaffMember} from "../../../store/staff/thunks/thunks";
import {useAppDispatch, useAppSelector} from "../../../components/Hooks/redux";

export function useAbsenceConfigPage() {
    const userConfigStore = useAppSelector((state) => state.userAbsenceConfig);
    const {initialiseRoutes} = useRouteSetup();
    const dispatch = useAppDispatch();
    const params: any = useParams();

    useEffect(() => {
        initialiseRoutes();

        fetchData();

        return function () {
            dispatch(nullifyUserConfigStore());
            dispatch(nullifyStaffStore());
        };
    }, []);

    function fetchData() {
        const username = params.username;

        if (!username) {
            showErrorToast("Username must be specified to get users absence config");
            return;
        }

        dispatch(getUserAbsenceConfig(username));
        dispatch(fetchStaffMember(username));
    }

    return {
        userConfigStore
    };
}
