import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {showSuccessToast} from "../../../utils/toastUtils";
import {routeNames} from "../../Navigation/routeNames";
import {nullifyStaffStore, setExistingStaffMember} from "../../../store/staff/staffSlice";
import {UserData} from "../../../api/staff/code";
import {
    saveExistingStaffMember,
    saveNewStaffMember,
    validateStaffMember
} from "../../../store/staff/thunks/thunks";
import {useAppDispatch, useAsyncStoreDispatch} from "../../Hooks/redux";

export function useStaffForm() {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {searchQueries} = usePageUrl();
    const {handleNullableAsyncStoreDispatch} = useAsyncStoreDispatch();

    useEffect(() => {
        return function () {
            dispatch(nullifyStaffStore());
        };
    }, []);
    function updateStaffMember(entity: UserData) {
        dispatch(setExistingStaffMember(entity));
    }
    const saveStaffMember = async (user: UserData) => {
        const isStaffMemberValid = validateStaffMember(user);
        if (!isStaffMemberValid) return;
        if (user.username.length === 0) {
            //New Staff Member

            const saved = await handleNullableAsyncStoreDispatch<UserData>(
                saveNewStaffMember(user)
            );

            if (saved) {
                showSuccessToast(`Added ${getFullName(user)} to the system.`);
                backToStaffDatabase();
            }
            return;
        }

        const saved = await handleNullableAsyncStoreDispatch<UserData>(
            saveExistingStaffMember(user)
        );

        if (saved) {
            showSuccessToast(`Updated ${getFullName(user)} details in the system.`);
            backToStaffDatabase();
        }
    };

    const backToStaffDatabase = () => {
        const path = `${routeNames.staffDatabase.path}${searchQueries}`;
        history.replace(path);
    };

    const getFullName = (user: UserData) => {
        return `${user.firstName} ${user.lastName}`;
    };

    return {
        backToStaffDatabase,
        saveStaffMember,
        updateStaffMember
    };
}
