import {createSlice} from "@reduxjs/toolkit";
import {createDefaultNullableStoreState} from "../helpers/methods";
import {ReduxPayload} from "../helpers/types";

const initialState = createDefaultNullableStoreState<boolean>(false);

const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        setSidebarToggled(state, action: ReduxPayload<boolean>) {
            state.data = action.payload;
        }
    }
});

export const {setSidebarToggled} = sidebarSlice.actions;
export default sidebarSlice.reducer;
