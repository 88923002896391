import {createSlice} from "@reduxjs/toolkit";
import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {getUserAbsenceConfig} from "./thunks/thunks";
import {UserAbsenceConfig} from "../../api/staff/code";

const initialState = createDefaultNullableStoreState<UserAbsenceConfig>(null);

const userAbsenceConfigSlice = createSlice({
    name: "userAbsenceConfig",
    initialState,
    reducers: {
        nullifyUserConfigStore(state) {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(getUserAbsenceConfig, builder);
    }
});

export const {nullifyUserConfigStore} = userAbsenceConfigSlice.actions;
export default userAbsenceConfigSlice.reducer;
