import React from "react";
import {CardBody} from "../Cards/Card";
import Row from "../Grid/Row";
import Col from "../Grid/Col";
import Input from "../Input/Input";
import {StaffFormProps} from "./StaffForm";
import {useUserData} from "../Hooks/useUserData";
import AuthAmI from "../AuthAmI/AuthAmI";
import {StaffAccessLevel} from "../../api/staff/code";

function StaffDetails({user, onChange}: StaffFormProps) {
    const currentUser = useUserData();
    return (
        <CardBody className="staff-form-body-wrapper">
            <div className="staff-form-body left">
                <p className="staff-form-section-label">Staff Details</p>
            </div>
            <div className="staff-form-body right">
                <Row className="form-row-wrapper">
                    <Col>
                        <Input
                            value={user.firstName}
                            name={"First Name"}
                            onChange={(event) => {
                                const {value} = event.target;
                                onChange({
                                    ...user,
                                    firstName: value
                                });
                            }}
                            label="First Name *"
                            placeholder="Enter first name..."
                            withErrorPlaceholder
                            debounce
                            type={"text"}
                        />
                    </Col>
                    <Col>
                        <Input
                            value={user.lastName}
                            name={"Last Name"}
                            onChange={(event) => {
                                const {value} = event.target;
                                onChange({
                                    ...user,
                                    lastName: value
                                });
                            }}
                            label="Last Name *"
                            placeholder="Enter last name..."
                            withErrorPlaceholder
                            debounce
                            type={"text"}
                        />
                    </Col>
                </Row>
                <Row className="form-row-wrapper">
                    <Col>
                        <Input
                            value={user.email}
                            name={"Email"}
                            onChange={(event) => {
                                const {value} = event.target;
                                onChange({
                                    ...user,
                                    email: value
                                });
                            }}
                            label="Email *"
                            placeholder="Enter email..."
                            withErrorPlaceholder
                            debounce
                            type={"email"}
                        />
                    </Col>
                </Row>
                {/*For when staff management is opened to users. This ensures only Admins can alter job titles*/}
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <Row className="form-row-wrapper">
                        <Col>
                            <Input
                                value={user.jobTitle}
                                name={"Job Title"}
                                onChange={(event) => {
                                    const {value} = event.target;
                                    onChange({
                                        ...user,
                                        jobTitle: value
                                    });
                                }}
                                label="Job Title"
                                placeholder="Enter Job Title..."
                                withErrorPlaceholder
                                debounce
                                type={"text"}
                            />
                        </Col>
                    </Row>
                </AuthAmI>
                <Row className="form-row-wrapper">
                    <Col>
                        <Input
                            value={user.nhsPin}
                            name={"NHS Pin Number"}
                            onChange={(event) => {
                                const {value} = event.target;
                                onChange({
                                    ...user,
                                    nhsPin: value
                                });
                            }}
                            label="NHS Pin"
                            placeholder="Enter nhs pin number..."
                            withErrorPlaceholder
                            debounce
                            type={"text"}
                        />
                    </Col>
                </Row>
                <Row className="form-row-wrapper">
                    <Col>
                        <Input
                            value={user.phoneNumber}
                            name={"Phone Number"}
                            onChange={(event) => {
                                const {value} = event.target;
                                onChange({
                                    ...user,
                                    phoneNumber: value
                                });
                            }}
                            label="Phone Number *"
                            placeholder="Enter phone number..."
                            withErrorPlaceholder
                            debounce
                            type={"tel"}
                        />
                    </Col>
                </Row>
                <Row className="form-row-wrapper">
                    <Col>
                        <Input
                            value={user.postCode}
                            name={"Post Code"}
                            onChange={(event) => {
                                const {value} = event.target;
                                onChange({
                                    ...user,
                                    postCode: value
                                });
                            }}
                            label="Post Code *"
                            placeholder="Enter postcode..."
                            withErrorPlaceholder
                            debounce
                            type={"text"}
                        />
                    </Col>
                </Row>
            </div>
        </CardBody>
    );
}

export default StaffDetails;
