import {DDProps} from "../../Helpers/dropdownUtils";
import {getSmsFiltersFromStaffList} from "../../../SMS/Helpers/smsHelpers";
import {sortTagsToDropdownProps} from "../../../SMS/Components/SMSFilters";
import React, {CSSProperties} from "react";
import {UserData} from "../../../../api/staff/code";
import {CalendarEntry, Venue} from "../../../../api/grs/code";

export interface GroupedOption {
    label: string;
    options: DDProps[];
}

export function getMetaDataDropdownOptions(
    staffList: UserData[],
    venues: Venue[],
    entries: CalendarEntry[]
): GroupedOption[] {
    const tagOptions = getUserTagsOptions(staffList);
    const venueOptions = getVenueOptions(venues, entries);
    const shiftOptions = getShiftOptions(entries);

    return [tagOptions, venueOptions, shiftOptions];
}

export function getUserTagsOptions(incomingStaffList: UserData[]): GroupedOption {
    const tags = getSmsFiltersFromStaffList(incomingStaffList);
    const options = sortTagsToDropdownProps(tags);

    return {
        label: "Tags",
        options
    };
}

export function getVenueOptions(venues: Venue[], entries: CalendarEntry[]): GroupedOption {
    const venueIds = entries.map((entry) => entry.venueId || 0);
    const venuesForMonth = getAllApplicableVenuesForMeta(venueIds, venues);
    return {
        label: "Venues",
        options: venuesForMonth.map((v) => {
            return {
                label: v.name,
                value: v.name
            };
        })
    };
}

// Checks against entries to get venue Ids. So the meta dropdown will only show venues that are being assigned to per event
function getAllApplicableVenuesForMeta(venueIds: number[], venues: Venue[]): Venue[] {
    const venueMap = new Map<number, Venue>();

    for (const id of venueIds) {
        const venue = venues.find((v) => v.id === id);
        if (!venue) continue;
        venueMap.set(id, venue);
    }

    return Array.from(venueMap.values());
}

//Get shift names. If there are any duplicates, we erase them and feed a unique array
export function getShiftOptions(entries: CalendarEntry[]): GroupedOption {
    return {
        label: "Shifts",
        options: getUniqueDropdownItems(entries)
    };
}

function getUniqueDropdownItems(entries: CalendarEntry[]): DDProps[] {
    const entryOptions = getEntryOptions(entries);
    const options = new Map<string, DDProps>();

    for (const eOption of entryOptions) {
        options.set(eOption.label, eOption);
    }

    return Array.from(options.values());
}

function getEntryOptions(entries: CalendarEntry[]): DDProps[] {
    const defaultName = "New Calendar Entry";
    return entries.map((e) => {
        return {label: e.description || defaultName, value: e.description || defaultName};
    });
}

const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
};
const dataLabelStyles: CSSProperties = {
    fontSize: 12,
    fontWeight: "bold",
    padding: "0 0 0 12px",
    color: "#1a2a35"
};

const groupBadgeStyles: CSSProperties = {
    backgroundColor: "#d0d700",
    borderRadius: "2em",
    color: "#1a2a35",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center"
};

export const formatGroupLabel = (data: GroupedOption) => (
    <div style={groupStyles}>
        <span style={dataLabelStyles}>{data.label}</span>
        <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
);
