import React from "react";

function ErrorFieldMessage({error, withErrorPlaceholder}: ErrorFieldMessageProps) {
    return (
        <React.Fragment>
            {error?.length ? (
                <small className="field-error-message" data-testid={"error-message"}>
                    {error}
                </small>
            ) : (
                <React.Fragment>
                    {withErrorPlaceholder && (
                        <div
                            className="field-error-message-placeholder"
                            data-testid={"error-message-placeholder"}
                        />
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default ErrorFieldMessage;

interface ErrorFieldMessageProps {
    error?: string;
    withErrorPlaceholder?: boolean;
}
