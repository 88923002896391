import React from "react";
import FormHeader from "../../../../components/Form/FormHeader";
import FormRow from "../../../../components/Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import FormRowWithTooltip from "../../../../components/Form/FormRowWithTooltip";
import {IconType} from "../../../../components/Icon/Icon";
import Toggle, {ToggleSize} from "../../../../components/Toggle/Toggle";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../components/Button/MCButton";
import FormActionContainer from "../../../../components/Form/FormActionContainer";
import {useEditRegionForm} from "../Hooks/useEditRegionForm";
import {Region} from "../../../../api/staff/code";

function EditRegionForm(props: Region) {
    const {updateRegionState, backToRegionList, save} = useEditRegionForm(props);
    return (
        <React.Fragment>
            <FormHeader headerName={"Location Form"} />
            <FormRow rowName={"Name"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className="input-fields"
                    value={props.name}
                    onChange={(event) => {
                        updateRegionState({
                            ...props,
                            name: event.target.value
                        });
                    }}
                    placeholder="Enter name here..."
                    type={"text"}
                />
            </FormRow>
            <FormRow rowName={"Postcode"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className="input-fields"
                    value={props.postcode}
                    onChange={(event) => {
                        updateRegionState({
                            ...props,
                            postcode: event.target.value
                        });
                    }}
                    placeholder="Enter postcode here..."
                    type={"text"}
                />
            </FormRow>
            <FormRowWithTooltip
                tooltipText={`Toggle to set the Region as archived. An archived region will not show up as an option. Region ${
                    props.archived ? "is" : "is NOT"
                } archived.`}
                icon={IconType.Question}
                rowName={"Archived?"}
                columnDetailClassName={"pl-3 pr-3"}
            >
                <Toggle
                    checked={props.archived}
                    onChange={() => {
                        let archived = props.archived;
                        updateRegionState({
                            ...props,
                            archived: (archived = !archived)
                        });
                    }}
                    size={ToggleSize.Large}
                />
            </FormRowWithTooltip>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Save"}
                    onClick={save}
                    className="mr-3"
                    colour={ButtonColourOptions.Yellow}
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Cancel"}
                    onClick={backToRegionList}
                    colour={ButtonColourOptions.DarkBlue}
                />
            </FormActionContainer>
        </React.Fragment>
    );
}

export default EditRegionForm;
