import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../components/Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import YearlyAbsenceForm from "./YearlyAbsenceForm";
import {useEditUserAbsenceConfigForm} from "../../Hooks/useEditUserAbsenceConfigForm";
import moment from "moment";
import {UserAbsenceConfigYear} from "../../../../api/staff/code";

function AddYearlyConfig() {
    const {toggle, isShown} = useModal();
    const {username} = useEditUserAbsenceConfigForm();

    function generateBlankYearlyAbsence(): UserAbsenceConfigYear {
        return {
            username,
            holidayLimit: 28,
            year: moment().year()
        };
    }
    return (
        <React.Fragment>
            <div className="row mt-5 ml-0 mr-0">
                <div className="col d-flex justify-content-end pr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Yearly Config"}
                        onClick={toggle}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                </div>
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Yearly Config"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <YearlyAbsenceForm
                            yearConfig={generateBlankYearlyAbsence()}
                            onClose={toggle}
                            isNewConfig={true}
                        />
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
}

export default AddYearlyConfig;
