import React, {ReactNode} from "react";

function Row({className, children}: Props) {
    return <div className={`row ${className || ""}`}>{children}</div>;
}

export default Row;

interface Props {
    className?: string;
    children: ReactNode;
}
