import {createSlice} from "@reduxjs/toolkit";
import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {Venue} from "../../api/grs/code";
import {fetchAllVenues} from "./thunks/thunks";

const initialState = createDefaultNullableStoreState<Venue[]>([]);

const venueListSlice = createSlice({
    name: "venueList",
    initialState,
    reducers: {
        nullifyVenueListStore(state) {
            state.data = [];
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(fetchAllVenues, builder);
    }
});

export const {nullifyVenueListStore} = venueListSlice.actions;
export default venueListSlice.reducer;
