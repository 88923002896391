import React from "react";
import SidebarTabs from "../../Tabs/SidebarTabs";
import useMcConfig from "../../../../../../components/Hooks/useMcConfig";
import {useAppSelector} from "../../../../../../components/Hooks/redux";

const SidebarMenu = () => {
    const navigationItems = useAppSelector((state) => state.navigation.data) || [];
    const {config} = useMcConfig();
    return (
        <React.Fragment>
            <div className="animateSidebarIn sidebar-content">
                <SidebarTabs
                    navigationItems={navigationItems}
                    externalNavigation={config.navigation}
                    systemDetails={config.systemDetails}
                />
            </div>
        </React.Fragment>
    );
};

export default SidebarMenu;
