import {createAsyncThunk} from "@reduxjs/toolkit";
import {handleServiceCall} from "../../helpers/methods";
import StaffManagementService from "../../../services/staff/StaffManagementService";
import {UserAbsenceConfigKey, UserAbsenceConfigYear} from "../../../api/staff/code";

const deleteUserYearConfig = createAsyncThunk(
    "userconfig/year/delete",
    async (config: UserAbsenceConfigKey) => {
        return await handleServiceCall(
            StaffManagementService.absenceApi.deleteUserAbsenceConfigYear(config),
            "POST"
        );
    }
);

const saveUserYearConfig = createAsyncThunk(
    "userconfig/year/save",
    async (config: UserAbsenceConfigYear) => {
        return await handleServiceCall(
            StaffManagementService.absenceApi.setUserYearAbsenceConfig(config),
            "POST"
        );
    }
);

export {deleteUserYearConfig, saveUserYearConfig};
