//Getting enum as a generic. Accepts string/numeric/heterogeneous

import {ClinicalGrade, MfaType, StaffAccessLevel} from "../api/staff/code";

export type Enum<E> = Record<keyof E, number | string> & {[k: number]: string};

export function getStaffAccessLevelFromString(value: string): StaffAccessLevel {
    return StaffAccessLevel[value as keyof typeof StaffAccessLevel];
}

export function getClinicalGradeFromString(value: string): ClinicalGrade {
    if (value === uiFriendlyClinicalGrades.EAC) {
        return ClinicalGrade.EAC;
    }
    return ClinicalGrade[value as keyof typeof ClinicalGrade];
}

export function getMfaTypeFromString(value: string): MfaType {
    if (value === uiFriendlyMfaTypes.Totp) {
        return MfaType.Totp;
    }
    return MfaType[value as keyof typeof MfaType];
}

// eslint-disable-next-line no-shadow
export enum uiFriendlyClinicalGrades {
    Paramedic = "Paramedic",
    Technician = "Technician",
    FirstResponder = "FirstResponder",
    EAC = "Emergency Ambulance Crew",
    Nurse = "Nurse",
    None = "None"
}

// eslint-disable-next-line no-shadow
export enum uiFriendlyMfaTypes {
    Sms = "SMS",
    Totp = "Authenticator"
}
// eslint-disable-next-line no-shadow
export enum uiFriendlyMfaTypesNoTotp {
    Sms = "SMS"
}
