import {useEffect, useState} from "react";
import {MultiValue} from "react-select";
import {DropdownOption, ServiceDropdownMulti} from "../../Types/types";
import {useDropdownCore} from "./useDropdownCore";

export function useDropdownMulti<T>({
    storeName,
    storeKeyValue,
    storeKeyLabel,
    onChange,
    ids,
    reduxDispatchClear,
    reduxDispatchFetch
}: ServiceDropdownMulti<T>) {
    const {options, reduxStore} = useDropdownCore({
        storeName,
        storeKeyValue,
        storeKeyLabel,
        reduxDispatchFetch,
        reduxDispatchClear
    });
    const [selectedOptions, setSelectedOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
        if (!ids) return;
        if (!options.length) return;
        if (!ids.length) return;

        const foundOptions: DropdownOption[] = [];
        for (const id of ids) {
            const newOption = options.find((item) => item.value === id);
            if (!newOption) continue;
            foundOptions.push(newOption);
        }

        setSelectedOptions(foundOptions);
    }, [ids, options]);

    function handleDropdownChange(newValue: MultiValue<DropdownOption>): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setSelectedOptions(newValue);
        const onChangeOption = getReduxStoreObjectFromDropdownOption(newValue);
        onChange(onChangeOption);
    }

    function getReduxStoreObjectFromDropdownOption(
        entity: MultiValue<DropdownOption>
    ): T[] | undefined | null {
        if (!entity) return;
        if (!reduxStore) return;

        const arr: T[] = [];

        for (const opt of entity) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const newOption = reduxStore.data.find((item: T) => item[storeKeyValue] === opt.value);
            if (!newOption) continue;
            arr.push(newOption);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return arr;
    }

    return {
        options,
        selectedOptions,
        handleDropdownChange
    };
}
