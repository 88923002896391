import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../components/Navigation/routeNames";
import {usePageUrl} from "../../../../components/Hooks/usePageUrl";
import {useEffect} from "react";
import {useAppDispatch, useAsyncStoreDispatch} from "../../../../components/Hooks/redux";
import {nullifyRegionStore, updateRegion} from "../../../../store/regions/single/regionSlice";
import {Region} from "../../../../api/staff/code";
import {saveRegion} from "../../../../store/regions/single/thunks/thunks";

export function useEditRegionForm(props: Region) {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const {searchQueries} = usePageUrl();
    const {handleNonNullableAsyncStoreDispatch} = useAsyncStoreDispatch();

    useEffect(() => {
        return function () {
            dispatch(nullifyRegionStore());
        };
    }, []);
    function updateRegionState(updatedRegion: Region) {
        dispatch(updateRegion(updatedRegion));
    }

    async function save() {
        const success: boolean = await handleNonNullableAsyncStoreDispatch(saveRegion(props));

        if (!success) return;

        await backToRegionList();
    }

    async function backToRegionList() {
        history.replace(`${routeNames.regionList.path}${searchQueries}`);
    }

    return {
        updateRegionState,
        save,
        backToRegionList
    };
}
