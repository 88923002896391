import React from "react";
import {StaffMessengerLogsTableRow} from "./StaffMessengerLogsTable";
import {Modal, useModal} from "pulse-modal";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Icon, {IconType} from "../../../components/Icon/Icon";
import StaffMessengerLogsRecipientsTable from "./StaffMessengerLogsRecipientsTable";

function StaffMessengerLogsActions(props: StaffMessengerLogsTableRow) {
    const {toggle, isShown} = useModal();
    return (
        <React.Fragment>
            <div className="icon-tooltip-wrapper-md mr-3">
                <Tooltip tooltipText={"View SMS Recipients"} size={"md"} place={"left"}>
                    <Icon
                        onClick={async () => {
                            toggle();
                        }}
                        rootElement={"Span"}
                        icon={IconType.Eye}
                        size={"Medium"}
                        className="cursor-pointer"
                    />
                </Tooltip>
            </div>
            <Modal
                modalSize={"lg"}
                title={"SMS Recipients"}
                bodyChildren={<StaffMessengerLogsRecipientsTable {...props.actions} />}
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
}

export default StaffMessengerLogsActions;
