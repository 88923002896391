import {ActionReducerMapBuilder} from "@reduxjs/toolkit";
import {NonNullableStore, NullableStore, ServiceCallType} from "./types";
import {AxiosResponse} from "axios";
import {showErrorToast} from "../../utils/toastUtils";
import {StatusCode4xx} from "store-fetch-wrappers";
import {statusCodeCallback} from "./storeHelpers";

export function createDefaultNullableStoreState<T>(data: T | null | undefined): NullableStore<T> {
    return {
        data,
        loading: false,
        error: null
    };
}

export function createDefaultStoreState<T>(data: T): NonNullableStore<T> {
    return {
        data,
        loading: false,
        error: null
    };
}

export function createBuildHandlers<T>(
    thunkFunction: any,
    builder: ActionReducerMapBuilder<NullableStore<T>>
) {
    builder.addCase(thunkFunction.pending, (state) => {
        state.loading = true;
    });
    builder.addCase(thunkFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
    });
    builder.addCase(thunkFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
    });
}

export async function handleServiceCall<T>(
    promise: Promise<AxiosResponse<T, any>>,
    type: ServiceCallType
): Promise<T | undefined | null> {
    switch (type) {
        case "GET":
            return await handleGet(promise);
        case "DELETE":
            return await handleDelete(promise);
        case "POST":
            return await handlePost(promise);

        default:
            return undefined;
    }
}

async function handleGet<T>(promise: Promise<AxiosResponse<T, any>>) {
    try {
        const response = await promise;

        if (response.status === 200) {
            return response.data;
        }
    } catch (error: any) {
        handleStatusCode(error.response.status, statusCodeCallback);
        showErrorToast(error.response.data.message);
    }
}

async function handlePost<T>(promise: Promise<AxiosResponse<T, any>>) {
    try {
        const response = await promise;

        if (response.status === 200 || response.status === 204) {
            return response.data;
        }
    } catch (error: any) {
        handleStatusCode(error.response.status, statusCodeCallback);
        showErrorToast(error.response.data.message);
    }
}

async function handleDelete<T>(promise: Promise<AxiosResponse<T, any>>) {
    try {
        const response = await promise;

        if (response.status === 200 || response.status === 204) {
            return response.data;
        }
    } catch (error: any) {
        handleStatusCode(error.response.status, statusCodeCallback);
        showErrorToast(error.response.data.message);
    }
}

function handleStatusCode(
    code: number | undefined,
    statusCodeAction: (newCode: StatusCode4xx) => void
): void {
    if (!code) return;
    switch (code) {
        case 401:
            statusCodeAction(StatusCode4xx.FourZeroOne);
            return;
        case 403:
            statusCodeAction(StatusCode4xx.FourZeroThree);
            return;
        case 404:
            statusCodeAction(StatusCode4xx.FourZeroFour);
            return;
    }
}
