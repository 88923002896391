import React from "react";
import mcLogo from "../../../../../assets/mc-logo.svg";
const Footer = () => {
    const year = () => {
        return new Date().getFullYear();
    };

    return (
        <div className="footer">
            <div className="nav-container">
                <div className="row ml-0 mr-0">
                    <div className="col d-flex justify-content-end">
                        <img src={mcLogo} className="mc-logo mr-0" alt="" />
                    </div>
                </div>
                <div className="footer-line mr-2 ml-2" />
                <p className="copyright mr-2 ml-2 pt-3 pb-3 footer-text text-right mb-0">
                    &copy; {year()} Medicare EMS Group UK Limited
                </p>
            </div>
        </div>
    );
};

export default Footer;
