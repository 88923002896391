import React from "react";
import {Card, CardFooter, CardHeader} from "../Cards/Card";
import {StaffAccessLevel, UserData} from "../../api/staff/code";
import StaffDetails from "./StaffDetails";
import AdminDetails from "./AdminDetails";

import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {useStaffForm} from "./hooks/useStaffForm";
import AuthAmI from "../AuthAmI/AuthAmI";

function StaffForm(props: UserData) {
    const {updateStaffMember, saveStaffMember, backToStaffDatabase} = useStaffForm();
    return (
        <React.Fragment>
            <Card>
                <CardHeader>
                    <h5>Staff Member Form</h5>
                </CardHeader>
                <StaffDetails user={props} onChange={updateStaffMember} />
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <AdminDetails user={props} onChange={updateStaffMember} />
                </AuthAmI>
                <CardFooter className="d-flex justify-content-end">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={() => saveStaffMember(props)}
                        className="mr-3"
                        colour={ButtonColourOptions.Yellow}
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={backToStaffDatabase}
                        colour={ButtonColourOptions.DarkBlue}
                    />
                </CardFooter>
            </Card>
        </React.Fragment>
    );
}

export default StaffForm;

export interface StaffFormProps {
    user: UserData;
    onChange: (entity: UserData) => void;
}
