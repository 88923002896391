import React from "react";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../components/Table/NoItemsMessage";
import {convertInternationalNumberToUk} from "../../../utils/numberUtils";
import {SmsHistory} from "../../../api/staff/code";

function StaffMessengerLogsRecipientsTable(props: SmsHistory): JSX.Element {
    return (
        <React.Fragment>
            <PulseTable
                items={toTableRow(props)}
                headers={{
                    staffMember: "Staff Member",
                    telephone: "Telephone"
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no recipients for this message"} />
                }
            />
        </React.Fragment>
    );
}

export default StaffMessengerLogsRecipientsTable;

interface TableRow {
    staffMember: string;
    telephone: string;
}

function toTableRow(props: SmsHistory): TableRow[] {
    return props.recipients.map((item) => {
        return {
            staffMember: item.name,
            telephone: convertInternationalNumberToUk(item.phone)
        };
    });
}
