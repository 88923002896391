import React, {Fragment, useEffect, useState} from "react";
import {nanoid} from "nanoid";

const Toggle = ({
    checked,
    onChange,
    size,
    disabled,
    name,
    value,
    label,
    withErrorPlaceholder
}: ToggleProps) => {
    const buttonId = nanoid();

    const [toggleClassName, setToggleClassName] = useState("");

    useEffect(() => {
        switch (size) {
            case ToggleSize.Large:
                setToggleClassName("dmcheck");
                break;
            case ToggleSize.Small:
                setToggleClassName("dmcheck-sm");
                break;
        }
    }, [size]);
    return (
        <Fragment>
            <div className="toggle-wrapper">
                {label && (
                    <label
                        data-testid="input-label"
                        className={`field-label mb-0 mr-5`}
                        htmlFor={name}
                    >
                        {label}
                    </label>
                )}
                <span className="toggle-control">
                    <input
                        className={toggleClassName}
                        type="checkbox"
                        checked={checked}
                        onChange={onChange}
                        id={buttonId}
                        disabled={disabled}
                        name={name}
                        value={value}
                    />
                    <label htmlFor={buttonId} />
                </span>
            </div>
            {withErrorPlaceholder && <div className="field-error-message-placeholder" />}
        </Fragment>
    );
};

export default Toggle;

export interface ToggleProps {
    checked: any;
    onChange: any;
    size: ToggleSize;
    disabled?: boolean;
    name?: string;
    value?: string;
    label?: string;
    withErrorPlaceholder?: boolean;
}

// eslint-disable-next-line no-shadow
export enum ToggleSize {
    Small = "Small",
    Large = "Large"
}
