import React from "react";
import {useEditRegionContainer} from "./Hooks/useEditRegionContainer";
import {WithServiceState} from "store-fetch-wrappers";
import EditRegionForm from "./Components/EditRegionForm";

const ServiceWrapper = WithServiceState(EditRegionForm);

function RegionContainer() {
    const {regionStore} = useEditRegionContainer();
    return (
        <div className="page-container">
            <ServiceWrapper
                loading={regionStore.loading}
                error={regionStore.error}
                data={regionStore.data}
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                showLoadingText={true}
            />
        </div>
    );
}

export default RegionContainer;
