import {createAsyncThunk} from "@reduxjs/toolkit";
import StaffManagementService from "../../../../services/staff/StaffManagementService";
import {handleServiceCall} from "../../../helpers/methods";
import {Region} from "../../../../api/staff/code";
import {showErrorToast} from "../../../../utils/toastUtils";

const getRegion = createAsyncThunk("regions/get/byid", async (id: number) => {
    return await handleServiceCall(StaffManagementService.regionsApi.getRegion(id), "GET");
});

const saveRegion = createAsyncThunk("regions/save", async (region: Region) => {
    const valid = isRegionValid(region);

    if (!valid) throw Error("Location not valid");

    return await handleServiceCall(StaffManagementService.regionsApi.saveRegion(region), "POST");
});

export {getRegion, saveRegion};

function isRegionValid(candidate: Region) {
    if (candidate.name.length < 3) {
        showErrorToast("Location name must be more or equal to 3 characters");
        return false;
    }

    if (candidate.postcode.length < 2) {
        showErrorToast("Location postcode must be more or equal to 2 characters");
        return false;
    }

    return true;
}
