import React, {ReactNode} from "react";

function Col({className, children}: Props) {
    return <div className={`col ${className || ""}`}>{children}</div>;
}

export default Col;

interface Props {
    className?: string;
    children: ReactNode;
}
