import React, {useEffect} from "react";
import SMSFilters from "./SMSFilters";
import SMSBody from "./SMSBody";
import SMSActions from "./SMSActions";
import {nullifySMSStore} from "../../../store/sms/smsSlice";
import {useAppDispatch} from "../../Hooks/redux";

const SMSForm = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        return function () {
            dispatch(nullifySMSStore());
        };
    }, []);
    return (
        <React.Fragment>
            <div className="fadeIn">
                <SMSFilters />
                <SMSBody />
                <SMSActions />
            </div>
        </React.Fragment>
    );
};

export default SMSForm;
