import {createSlice} from "@reduxjs/toolkit";
import {AuthStore} from "../helpers/types";
import {createBuildHandlers} from "../helpers/methods";
import {fetchUserData, login, logout} from "./thunks/thunks";

const initialState: AuthStore = {
    loading: false,
    authenticated: false,
    error: null,
    data: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        unAuthenticateUser(state) {
            state.authenticated = false;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(login, builder);
        createBuildHandlers(logout, builder);

        //Fetch User
        builder.addCase(fetchUserData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
            state.authenticated = !!action.payload;
        });
        builder.addCase(fetchUserData.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || null;
        });
    }
});

export const {unAuthenticateUser} = authSlice.actions;

export default authSlice.reducer;
