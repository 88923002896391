import React, {forwardRef} from "react";

// eslint-disable-next-line react/display-name
const DatePickerInputButton = forwardRef<any>(({value, onClick}: any, ref) => (
    <button
        className="input-fields date-picker-button"
        placeholder="Select date..."
        onClick={onClick}
        ref={ref}
    >
        {value ? <span>{value}</span> : <span className="placeholder-text">Select date...</span>}
    </button>
));
export default DatePickerInputButton;
