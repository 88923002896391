import React, {useEffect, useState} from "react";
import {StaffAccessLevel} from "../../api/staff/code";
import {useUserData} from "../Hooks/useUserData";
import useMcConfig from "../Hooks/useMcConfig";
import {getUserAccessLevel} from "../../utils/userDataUtils";

const AuthAmI = ({accessLevels, children}: Props) => {
    const user = useUserData();
    const [canSee, setCanSee] = useState<boolean>(false);
    const {config} = useMcConfig();

    useEffect(() => {
        if (!config.auth) return;
        if (user.username.length === 0) {
            setCanSee(false);
            return;
        }

        //Super admins see all things
        if (user.superAdmin) {
            setCanSee(true);
            return;
        }

        const systemAccessLevel = getUserAccessLevel(user, config);

        setCanSee(accessLevels.indexOf(systemAccessLevel) > -1);
    }, [user, config]);
    return <React.Fragment>{canSee ? children : null}</React.Fragment>;
};

export default AuthAmI;

interface Props {
    accessLevels: StaffAccessLevel[];
    children: JSX.Element | JSX.Element[] | number | string | boolean | undefined | null;
}
