/** An interface that will contain a date from and date to */
export interface DateRange {
    startDateInclusive: number;
    endDateExclusive: number;
}

export interface CwaChangeEvent<T> {
    onChange: (event: T) => void;
}

/** paged request config */
export const pagedRequestConfig: PagedRequestConfig = {
    resultsPerPage: 50
};

/** Base interface for paged request */
interface PagedRequestConfig {
    resultsPerPage: number;
}

export function getPageCount<T>(array: T[], numberPerPage: number): number {
    const total = Math.ceil(array.length / numberPerPage);
    return isNaN(total) ? 1 : total;
}
