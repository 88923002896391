import {createAsyncThunk} from "@reduxjs/toolkit";
import {handleServiceCall} from "../../helpers/methods";
import StaffManagementService from "../../../services/staff/StaffManagementService";

const getUserAbsenceConfig = createAsyncThunk(
    "userconfig/get/byusername",
    async (username: string) => {
        return await handleServiceCall(
            StaffManagementService.absenceApi.getUserAbsenceConfig(username),
            "POST"
        );
    }
);

export {getUserAbsenceConfig};
