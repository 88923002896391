import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../Store";
import {getAllMedicareStaffMembers} from "../../staffList/thunks/thunks";
import {fetchAllVenues} from "../../venueList/thunks/thunks";
import {SmsHistoryRequest} from "../../../api/staff/code";
import {getCalendarEntriesList} from "../../calendarEntryList/thunks/thunks";
import {handleServiceCall} from "../../helpers/methods";
import StaffManagementService from "../../../services/staff/StaffManagementService";

const getDataForSmsLogFiltersWithBlankTable = createAsyncThunk("sms/logs/get/blank", async () => {
    await store.dispatch(getAllMedicareStaffMembers());
    await store.dispatch(fetchAllVenues());

    return {
        entries: [],
        totalEntries: 0,
        pageNum: 0,
        numPerPage: 50,
        metadata: {}
    };
});

const getSmsLogsList = createAsyncThunk("sms/logs/get/list", async (request: SmsHistoryRequest) => {
    await store.dispatch(
        getCalendarEntriesList({
            startDate: request.startDateInclusive || 0,
            endDate: request.endDateExclusive || 0
        })
    );
    return handleServiceCall(StaffManagementService.contactApi.pageSmsLogs(request), "POST");
});

export {getDataForSmsLogFiltersWithBlankTable, getSmsLogsList};
