import React, {useEffect, useState} from "react";
import {
    getSidebarTabs,
    SidebarItemContainerProps,
    SidebarTabsProps,
    TabsProps
} from "./Helpers/sidebarTabsHelpers";
import SidebarItemContainer from "./SidebarItemContainer";
import {useUserData} from "../../../../../components/Hooks/useUserData";
import useMcConfig from "../../../../../components/Hooks/useMcConfig";

const SidebarTabs = (props: SidebarTabsProps) => {
    const [tabsProps, setTabsProps] = useState<TabsProps<SidebarItemContainerProps>>();
    const user = useUserData();
    const {config} = useMcConfig();
    useEffect(() => {
        if (user.username.length === 0) return;
        if (!config.auth) return;
        const tabs = getSidebarTabs(props, user, config);
        const currentTab = tabs[1];
        setTabsProps({tabs, currentTab});
    }, [props, user, config]);

    const handleSelectTab = (tab: SidebarItemContainerProps) => {
        if (!tabsProps) return;

        setTabsProps({
            ...tabsProps,
            currentTab: tab
        });
    };
    return (
        <React.Fragment>
            <ul className="nav nav-tab">
                {tabsProps &&
                    tabsProps.tabs.map((item: SidebarItemContainerProps) => {
                        return (
                            <li
                                key={item.id}
                                onClick={() => handleSelectTab(item)}
                                className={
                                    tabsProps.currentTab.id === item.id ? "tab active" : "tab"
                                }
                            >
                                <p className="mb-0">{item.name}</p>
                            </li>
                        );
                    })}
            </ul>
            {tabsProps && tabsProps.tabs.length > 0 && (
                <SidebarItemContainer {...tabsProps.currentTab} />
            )}
        </React.Fragment>
    );
};

export default SidebarTabs;
