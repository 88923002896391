import {
    UsersApi,
    LocationsApi,
    Absence2Api,
    AbsenceApi,
    RegionsApi,
    ContactApi,
    UserDocsApi
} from "../../api/staff/code";

export default class StaffManagementService {
    private static _instance: StaffManagementService;
    private static _usersApi: UsersApi;
    private static _locationsApi: LocationsApi;
    private static _absenceApi: AbsenceApi;
    private static _absence2Api: Absence2Api;
    private static _regionsApi: RegionsApi;
    private static _contactApi: ContactApi;
    private static _userDocsApi: UserDocsApi;

    private constructor() {
        if (StaffManagementService._instance) {
            throw new Error("Use StaffManagementService.instance instead of new");
        }
        StaffManagementService._instance = this;
    }

    static get instance() {
        return (
            StaffManagementService._instance ??
            (StaffManagementService._instance = new StaffManagementService())
        );
    }

    static get usersApi(): UsersApi {
        if (!StaffManagementService._usersApi) {
            StaffManagementService._usersApi = new UsersApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffManagementService._usersApi;
    }

    static get locationsApi(): LocationsApi {
        if (!StaffManagementService._locationsApi) {
            StaffManagementService._locationsApi = new LocationsApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffManagementService._locationsApi;
    }

    static get absenceApi(): AbsenceApi {
        if (!StaffManagementService._absenceApi) {
            StaffManagementService._absenceApi = new AbsenceApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffManagementService._absenceApi;
    }

    static get absence2Api(): Absence2Api {
        if (!StaffManagementService._absence2Api) {
            StaffManagementService._absence2Api = new Absence2Api({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffManagementService._absence2Api;
    }

    static get regionsApi(): RegionsApi {
        if (!StaffManagementService._regionsApi) {
            StaffManagementService._regionsApi = new RegionsApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffManagementService._regionsApi;
    }

    static get contactApi(): ContactApi {
        if (!StaffManagementService._contactApi) {
            StaffManagementService._contactApi = new ContactApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffManagementService._contactApi;
    }

    static get userDocsApi(): UserDocsApi {
        if (!StaffManagementService._userDocsApi) {
            StaffManagementService._userDocsApi = new UserDocsApi({
                basePath: process.env.REACT_APP_STAFF_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return StaffManagementService._userDocsApi;
    }
}
