import React, {useContext} from "react";
import {TopBarItem} from "./Helpers/topbarHelpers";
import TopbarItem from "./TopbarItem";
import SidebarBurger from "../Sidebar/Components/SidebarBurger";
import useMcConfig from "../../../../../components/Hooks/useMcConfig";
import LogoutButton from "../Buttons/LogoutButton";
import {LayoutHeaderContext} from "../../Context/LayoutHeaderContext";
import {Loading} from "store-fetch-wrappers";
import mcLogo from "../../../../../assets/mc-logo.svg";

const Topbar = () => {
    const {config, loading} = useMcConfig();
    const showHeader = useContext(LayoutHeaderContext);
    return (
        <React.Fragment>
            <div id="nav-header" className="header nav-container h-100 flex-row">
                <div className="row ml-0 mr-0 w-100">
                    <div className="pl-0 col">
                        <img src={mcLogo} className="mc-logo" alt="" />
                    </div>
                    {showHeader && (
                        <div className="ml-auto pl-0 pr-0 d-flex justify-content-end">
                            <div className="desktop">
                                <ul className="link-container nav-links-right mb-0">
                                    {loading ? (
                                        <Loading type={"dot"} showLoadingText={false} />
                                    ) : (
                                        <React.Fragment>
                                            {config.navigation.items.map(
                                                (item: TopBarItem, index: number) => {
                                                    return <TopbarItem {...item} key={index} />;
                                                }
                                            )}
                                            <LogoutButton />
                                        </React.Fragment>
                                    )}
                                </ul>
                            </div>
                            <div className="mobile pr-2">
                                <SidebarBurger />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Topbar;
