import React from "react";
import Icon, {IconType} from "../../../../../../components/Icon/Icon";
import {useFadeInFadeOut} from "../Hooks/useFadeInFadeOut";
import {useAppDispatch} from "../../../../../../components/Hooks/redux";
import {setSidebarToggled} from "../../../../../../store/sidebar/sidebarSlice";

const SidebarBurger = () => {
    const dispatch = useAppDispatch();
    const rootElementClassName = useFadeInFadeOut({fadeInFirst: false});
    const onBurgerClicked = () => {
        dispatch(setSidebarToggled(true));
    };
    return (
        <React.Fragment>
            <Icon
                rootElement={"Div"}
                rootElementClassName={`burger-menu ${rootElementClassName}`}
                icon={IconType.Menu}
                size={"Medium"}
                className="nav-links"
                onClick={onBurgerClicked}
            />
        </React.Fragment>
    );
};

export default SidebarBurger;
