import {createAsyncThunk} from "@reduxjs/toolkit";
import {Auth, CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import StaffManagementService from "../../../services/staff/StaffManagementService";

const login = createAsyncThunk("auth/login", async () => {
    return await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Cognito});
});

const logout = createAsyncThunk("auth/logout", async () => {
    await Auth.signOut();
    return await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Cognito});
});

const fetchUserData = createAsyncThunk("auth/fetch-user", async (username: string) => {
    const response = await StaffManagementService.usersApi.getUserByUsername(username);
    return response.data;
});

export {login, logout, fetchUserData};
