import React, {useEffect, useState} from "react";
import Select, {SingleValue} from "react-select";
import {DDProps} from "./Helpers/dropdownUtils";
import {DropdownOption} from "./Types/types";

function AccessDetailDropdown({enumOptions, id, onChange, assignedSystems}: Props) {
    const [selectedOption, setSelectedOption] = useState<DropdownOption | null>();
    const [options, setOptions] = useState<DDProps[]>([]);

    useEffect(() => {
        const opts: DDProps[] = [];

        const entries = Object.entries(enumOptions);

        for (const entry of entries) {
            const [value, label] = entry;
            const assigned = assignedSystems.findIndex((item) => value === item);

            if (assigned > -1) continue;

            opts.push({
                label: label as string,
                value
            });
        }

        setOptions(opts);
    }, [enumOptions, assignedSystems]);

    useEffect(() => {
        if (!id) return;
        if (options.length === 0) return;

        // const option = options.find((item) => item.value == id);
        const option: DDProps = {
            label: enumOptions[id],
            value: id
        };
        setSelectedOption(option);
    }, [id, options]);

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={options}
                value={selectedOption}
                onChange={(newValue) => {
                    onChange(newValue);

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setSelectedOption(newValue);
                }}
                placeholder="Select system"
                isDisabled={false}
                isClearable={false}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
}

export default AccessDetailDropdown;

interface Props {
    enumOptions: any;
    id: string;
    onChange: (newOption: SingleValue<DDProps>) => void;
    assignedSystems: string[];
}

// eslint-disable-next-line no-shadow
enum AccessDetailOptions {
    staffmanagement = "Staff Management",
    grs = "Group Rostering System",
    dashboard = "EMS Portal",
    xd2 = "X Drive",
    fms = "Fleet Management",
    absence = "Leave Management",
    oplog = "Op Logs",
    prf = "Patient Report Forms",
    cwa = "Clinical Waste Audit",
    mm = "Medicine Management",
    ppb = "My Documents",
    im = "Incidents"
}
