import {useHistory} from "react-router-dom";
import {routeNames} from "../../../components/Navigation/routeNames";
import {useAppSelector} from "../../../components/Hooks/redux";

export function useEditUserAbsenceConfigForm() {
    const userStore = useAppSelector((state) => state.staff);
    const history = useHistory();
    function getFullName(): string {
        const {data} = userStore;
        if (!data) return "";
        const {firstName, lastName} = data;
        return `${firstName} ${lastName}`;
    }

    function getUsername(): string {
        const {data} = userStore;
        if (!data) return "";
        const {username} = data;
        return username;
    }

    function backToStaffDatabase() {
        history.push({
            pathname: routeNames.staffDatabase.path
        });
    }

    return {
        userStore,
        fullName: getFullName(),
        username: getUsername(),
        backToStaffDatabase
    };
}
