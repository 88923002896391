import React from "react";
import {Modal, useModal} from "pulse-modal";
import {showSuccessToast} from "../../../utils/toastUtils";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Icon, {IconType} from "../../../components/Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import {Loading} from "store-fetch-wrappers";
import {UserData} from "../../../api/staff/code";
import {deleteExistingStaffMember} from "../../../store/staff/thunks/thunks";
import {
    useAppDispatch,
    useAppSelector,
    useAsyncStoreDispatch
} from "../../../components/Hooks/redux";
import {getAllMedicareStaffMembers} from "../../../store/staffList/thunks/thunks";

const DeleteStaffMemberButton = (props: UserData) => {
    const {isShown, toggle} = useModal();
    const dispatch = useAppDispatch();
    const staffStore = useAppSelector((state) => state.staff);
    const {handleNonNullableAsyncStoreDispatch} = useAsyncStoreDispatch();

    const deleteStaffMember = async () => {
        const success: boolean = await handleNonNullableAsyncStoreDispatch<boolean>(
            deleteExistingStaffMember(props.username)
        );

        if (success) {
            dispatch(getAllMedicareStaffMembers());
            showSuccessToast("Deleted staff member");
            toggle();
        }
    };
    return (
        <React.Fragment>
            <div className="icon-tooltip-wrapper-md mr-1">
                <Tooltip tooltipText={"Delete Staff Member"} size={"md"} place={"left"}>
                    <Icon
                        rootElement={"Div"}
                        icon={IconType.Bin}
                        size={"Medium"}
                        className="icon-dark"
                        onClick={toggle}
                    />
                </Tooltip>
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Delete Staff Member"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className="mb-0">
                                Are you sure you want to delete {props.firstName} {props.lastName}?
                            </p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        {staffStore.loading ? (
                            <React.Fragment>
                                <div className="col text-center">
                                    <Loading type={"three-ring"} showLoadingText={false} />
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={"Yes"}
                                    onClick={deleteStaffMember}
                                    colour={ButtonColourOptions.Yellow}
                                    className="mr-3"
                                />
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={"No"}
                                    onClick={toggle}
                                    colour={ButtonColourOptions.DarkBlue}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default DeleteStaffMemberButton;
