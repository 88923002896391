import React from "react";
import FormHeader from "../../../components/Form/FormHeader";
import FormRow from "../../../components/Form/FormRow";
import {useDefaultConfig} from "../Hooks/useDefaultConfig";
import FormActionContainer from "../../../components/Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import {Loading} from "store-fetch-wrappers";
import {UserAbsenceConfigDefault} from "../../../api/staff/code";

function DefaultConfig(props: UserAbsenceConfigDefault) {
    const {config, updateConfig, saveDefaultConfig, userDefaultConfigStore} =
        useDefaultConfig(props);
    return (
        <React.Fragment>
            <div className="mt-3">
                <FormHeader headerName={`Default Config`} />
                <FormRow rowName={"Default Holiday Limit"} columnDetailClassName={"pl-0 pr-0"}>
                    <input
                        value={config.defaultHolidayLimit}
                        type={"number"}
                        onChange={(event) => {
                            const {value} = event.target;

                            updateConfig({
                                ...config,
                                defaultHolidayLimit: +value
                            });
                        }}
                        className="input-fields"
                    />
                </FormRow>
                <FormActionContainer>
                    {userDefaultConfigStore.loading ? (
                        <Loading type={"three-ring"} showLoadingText={false} />
                    ) : (
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Save Default Config"}
                            onClick={saveDefaultConfig}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    )}
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
}

export default DefaultConfig;
