import {createSlice} from "@reduxjs/toolkit";
import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {saveUserDefaultConfig} from "./thunks/thunks";
import {UserAbsenceConfigDefault} from "../../api/staff/code";

const initialState = createDefaultNullableStoreState<UserAbsenceConfigDefault>(null);

const userAbsenceDefaultConfigSlice = createSlice({
    name: "userAbsenceDefaultConfig",
    initialState,
    reducers: {
        nullifyUserDefaultConfigStore(state) {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(saveUserDefaultConfig, builder);
    }
});

export const {nullifyUserDefaultConfigStore} = userAbsenceDefaultConfigSlice.actions;
export default userAbsenceDefaultConfigSlice.reducer;
