import React from "react";
import Select from "react-select";
import {useDropdownSingle} from "./Hooks/useDropdown/useDropdownSingle";
import {Region} from "../../api/staff/code";
import {BaseDropdownPropsSingle} from "./Types/types";

function RegionSingleDropdown({regionId, onChange, ...rest}: PulseRegionDropdown) {
    const {options, selectedOption, handleDropdownChange} = useDropdownSingle<Region>({
        storeName: "regionList",
        onChange,
        id: regionId,
        storeKeyLabel: "name",
        storeKeyValue: "id"
    });
    return (
        <Select
            className="search-bar"
            classNamePrefix="select-options"
            options={options}
            onChange={handleDropdownChange}
            isSearchable={rest.searchable}
            value={selectedOption}
            noOptionsMessage={() => rest.noItemsMessage}
            placeholder={rest.placeholder}
            isDisabled={rest.disabled}
            isClearable={rest.clearable}
            menuPortalTarget={document.body}
        />
    );
}

export default RegionSingleDropdown;

export interface PulseRegionDropdown extends BaseDropdownPropsSingle<Region> {
    regionId?: number;
}
