import React, {useEffect, useState} from "react";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../components/Table/NoItemsMessage";
import {toArray} from "../../../../utils/sortingUtils";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../components/Button/MCButton";
import {routeNames} from "../../../../components/Navigation/routeNames";
import {useHistory} from "react-router-dom";
import FilterContainer from "../../../../components/Filters/FilterContainer";
import RegionListTableFilters, {ArchiveFilter, RegionTableRequest} from "./RegionListTableFilters";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import Icon, {IconType} from "../../../../components/Icon/Icon";
import {usePageUrl} from "../../../../components/Hooks/usePageUrl";
import {Region} from "../../../../api/staff/code";

function RegionListTable(props: Region[]) {
    const history = useHistory();
    const [request, setRequest] = useState<RegionTableRequest>();
    const [items, setItems] = useState<TableRow[]>([]);
    const {searchQueries} = usePageUrl();

    const onRequestChanged = (incomingRequest: RegionTableRequest) => {
        if (JSON.stringify(incomingRequest) === JSON.stringify(request)) return;
        setRequest(incomingRequest);
    };

    useEffect(() => {
        if (!request) return;

        const filteredResults = getFilteredRegionTableResults(toArray(props), request);
        setItems(filteredResults);
    }, [request, props]);
    const addRegion = () => {
        history.push({
            pathname: routeNames.regionAdd.path,
            search: searchQueries
        });
    };
    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <RegionListTableFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <div className="row mt-5 ml-0 mr-0">
                <div className="col d-flex justify-content-end pr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Location"}
                        onClick={addRegion}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                </div>
            </div>
            <PulseTable
                items={items}
                headers={{
                    name: "Name",
                    postcode: "Postcode",
                    archived: "Archived?",
                    action: "Actions"
                }}
                customRenderers={{
                    archived: (item: TableRow) => {
                        return (
                            <div className="mfa-base-wrapper">
                                <Tooltip
                                    tooltipText={`Region is ${
                                        item.archived ? "archived" : "not archived"
                                    }`}
                                    size={"md"}
                                    place={"right"}
                                >
                                    <div
                                        className={`mfa-base ${
                                            item.archived ? "enabled" : "disabled"
                                        }`}
                                    />
                                </Tooltip>
                            </div>
                        );
                    },
                    action: (item: TableRow) => {
                        return (
                            <React.Fragment>
                                <div className="row ml-0 ml-0">
                                    <div className="icon-tooltip-wrapper-md mr-1">
                                        <Tooltip
                                            tooltipText={"Edit Region"}
                                            size={"md"}
                                            place={"left"}
                                        >
                                            <Icon
                                                rootElement={"Div"}
                                                icon={IconType.Edit}
                                                size={"Medium"}
                                                className="icon-dark"
                                                onClick={() => {
                                                    const path = `${routeNames.regionEdit.path}/${item.action.id}`;
                                                    history.push({
                                                        pathname: path,
                                                        search: searchQueries
                                                    });
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                </div>
                            </React.Fragment>
                        );
                    }
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no regions matching the criteria"} />
                }
            />
        </React.Fragment>
    );
}

export default RegionListTable;

interface TableRow {
    name: string;
    postcode: string;
    archived: boolean;
    action: Region;
}

function toTableRow(props: Region[]): TableRow[] {
    return toArray(props).map((region) => {
        return {
            name: region.name,
            postcode: region.postcode,
            archived: region.archived,
            action: region
        };
    });
}

function getFilteredRegionTableResults(
    regions: Region[],
    {archived}: RegionTableRequest
): TableRow[] {
    switch (archived) {
        case ArchiveFilter.All:
            return toTableRow(regions).sort((a, b) => Number(a.archived) - Number(b.archived));
        case ArchiveFilter.Archived:
            return toTableRow(regions.filter((item) => item.archived));
        case ArchiveFilter.Unarchived:
            return toTableRow(regions.filter((item) => !item.archived));
    }
}
