import axios from "axios";
import {TopBar} from "./pages/Layouts/Layout/Components/Header/Helpers/topbarHelpers";

export interface McConfig {
    auth: any;
    navigation: TopBar;
    systemDetails: CurrentSystemDetails;
    timezone: string;
}

export async function getConfig(): Promise<McConfig> {
    const config = await axios.get("/config.json");

    return config.data as McConfig;
}

export interface CurrentSystemDetails {
    abbreviatedAdminName: string;
    abbreviatedStaffName: string;
    elongatedAdminName: string;
    elongatedStaffName: string;
    defaultName: string;
    moduleName: string;
}

export interface Auth {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    mandatorySignIn: boolean;
    cookieStorage: CookieStorage;
    authenticationFlowType: string;
    oauth: OAuth;
}

interface CookieStorage {
    domain: string;
    path: string;
    expires: number;
    secure: boolean;
}
interface OAuth {
    domain: string;
    scope: Array<string>;
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
}

export function generateBlankConfig(): McConfig {
    return {
        //Hardcode the timezone to London as MC operations are local to the UK.
        //Actual timezone will be loaded in the config
        timezone: "Europe/London",
        navigation: {items: []},
        systemDetails: {
            abbreviatedAdminName: "",
            abbreviatedStaffName: "",
            elongatedAdminName: "",
            elongatedStaffName: "",
            defaultName: "",
            moduleName: ""
        },
        auth: undefined
    };
}
