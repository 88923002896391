import React, {useEffect, useState} from "react";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Icon, {IconType} from "../../../components/Icon/Icon";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import AutoPasswordResetForm from "./AutoPasswordResetForm";
import ManualPasswordResetForm from "./ManualPasswordResetForm";
import {UserData} from "../../../api/staff/code";

const ResetPasswordButton = (props: UserData) => {
    const {isShown, toggle} = useModal();
    const [passwordResetType, setPasswordResetType] = useState<PasswordResetType>(
        PasswordResetType.None
    );
    useEffect(() => {
        if (isShown) {
            setPasswordResetType(PasswordResetType.None);
        }
    }, [isShown]);

    return (
        <React.Fragment>
            <div className="icon-tooltip-wrapper-md mr-1">
                <Tooltip tooltipText={"Reset Password"} size={"md"} place={"left"}>
                    <Icon
                        rootElement={"Div"}
                        icon={IconType.ResetPassword}
                        size={"Medium"}
                        className="icon-dark"
                        onClick={toggle}
                    />
                </Tooltip>
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Reset Password"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        {passwordResetType === PasswordResetType.None && (
                            <div className="row ml-0 mr-0">
                                <div className="col d-grid">
                                    <MCButton
                                        size={ButtonSize.Large}
                                        innerValue={"Auto"}
                                        onClick={() => setPasswordResetType(PasswordResetType.Auto)}
                                        colour={ButtonColourOptions.DarkBlue}
                                    />
                                </div>
                                <div className="col d-grid">
                                    <MCButton
                                        size={ButtonSize.Large}
                                        innerValue={"Manual"}
                                        onClick={() =>
                                            setPasswordResetType(PasswordResetType.Manual)
                                        }
                                        colour={ButtonColourOptions.DarkBlue}
                                    />
                                </div>
                            </div>
                        )}
                        {passwordResetType === PasswordResetType.Auto && (
                            <AutoPasswordResetForm user={props} onClose={toggle} />
                        )}
                        {passwordResetType === PasswordResetType.Manual && (
                            <ManualPasswordResetForm user={props} onClose={toggle} />
                        )}
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default ResetPasswordButton;

// eslint-disable-next-line no-shadow
enum PasswordResetType {
    Manual = "Manual",
    Auto = "Auto",
    None = "None"
}
