import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../Store";
import {BulkSMSRequest, UserData} from "../../../api/staff/code";
import {handleServiceCall} from "../../helpers/methods";
import StaffManagementService from "../../../services/staff/StaffManagementService";
import {
    getMetaDataForRequest,
    SelectedSMSStaffMember
} from "../../../components/SMS/Helpers/smsHelpers";
import {CalendarEntry, Venue} from "../../../api/grs/code";

const sendMassSms = createAsyncThunk("sms/send", async (smsDetails: SMSFormDetails) => {
    const loggedInUser = store.getState().auth.data;
    if (!loggedInUser) return;
    const bulkSmsRequest = getBulkSmsRequest(smsDetails, loggedInUser, false);

    return await handleServiceCall(
        StaffManagementService.contactApi.bulkSms(bulkSmsRequest),
        "POST"
    );
});

export {sendMassSms};

function getBulkSmsRequest(
    {bodyText, staffMembers}: SMSFormDetails,
    {username}: UserData,
    sendToEventStaff: boolean,
    entry?: CalendarEntry,
    venues?: Venue[]
): BulkSMSRequest {
    return {
        staffIds: staffMembers.map((item: SelectedSMSStaffMember) => item.staffId),
        message: bodyText,
        senderUsername: username,
        metadata: getMetaDataForRequest(staffMembers, sendToEventStaff, entry, venues)
    };
}

export interface SMSFormDetails {
    staffMembers: SelectedSMSStaffMember[];
    bodyText: string;
}
