import React, {useEffect, useState} from "react";
import {
    DDProps,
    getSelectedDropdownOptionByValue,
    sortUserDataToDropdownProps,
    StaffDropdownProps
} from "./Helpers/dropdownUtils";
import {UserData} from "../../api/staff/code";
import Select, {SingleValue} from "react-select";
import {Loading} from "store-fetch-wrappers/dist";
import {StaffLink} from "./Helpers/dropdownUtils";
import {useAppSelector} from "../Hooks/redux";

/** Dropdown that will show all users assigned in an entry or all users in the database. */
const StaffDropdown = (props: StaffDropdownProps) => {
    const [staffOptions, setStaffOptions] = useState<DDProps[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined>(undefined);

    const staffListStore = useAppSelector((state) => state.staffList);

    /** Get the staff list upon mounting */
    useEffect(() => {
        if (!staffListStore.data) return;
        processStaffListIncoming(getFilteredStaffList(staffListStore.data));
    }, [staffListStore]);

    const getFilteredStaffList = (staffList: UserData[]) => {
        if (props.accessLevelFilter) {
            return staffList.filter(
                (item: UserData) => item.accessLevel === props.accessLevelFilter
            );
        }
        return staffList;
    };

    /** Sort the staff list into dropdown props */
    const processStaffListIncoming = (users: UserData[]) => {
        const options = sortUserDataToDropdownProps(users);
        setStaffOptions(options);

        if (options.length === 0 || props.clearable) {
            setSelectedOption(undefined);
            props.changeOption(emptyStaffLink());
            return;
        }
        const option = getSelectedDropdownOptionByValue(props.id, options);
        setSelectedOption(option);
        props.changeOption({staffId: option.value.toString(), staffName: option.label});
    };

    /** Fired when a new option is selected */
    const handleStaffChange = (newValue: SingleValue<DDProps>) => {
        if (!newValue) {
            props.changeOption({staffId: "", staffName: ""});
            setSelectedOption(undefined);
            return;
        }
        //Using the data from the staffList
        const option = getSelectedDropdownOptionByValue(newValue.value, staffOptions);

        setSelectedOption(option);
        props.changeOption({staffId: option.value.toString(), staffName: option.label});
    };

    /** Empty staff link */
    const emptyStaffLink = (): StaffLink => {
        return {
            staffId: "",
            staffName: ""
        };
    };

    return (
        <React.Fragment>
            {staffListStore.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={staffOptions}
                    onChange={handleStaffChange}
                    isSearchable={props.searchable}
                    value={selectedOption}
                    noOptionsMessage={() => "No Staff Members Found"}
                    placeholder="Select member"
                    isDisabled={props.disabled}
                    isClearable={props.clearable}
                    menuPortalTarget={document.body}
                />
            )}
        </React.Fragment>
    );
};

export default StaffDropdown;
