import React from "react";
import MCButton, {MCButtonProps} from "./MCButton";

const MCButtonWithIndicator = ({numberBubbleValue, ...rest}: MCButtonWithIndicatorProps) => {
    return (
        <span className="position-relative">
            <MCButton {...rest} />
            <div className={`number-bubble-${rest.colour}`}>
                <p className="mb-0 number-bubble-value">{numberBubbleValue}</p>
            </div>
        </span>
    );
};

export default MCButtonWithIndicator;

interface MCButtonWithIndicatorProps extends MCButtonProps {
    numberBubbleValue: number;
}
