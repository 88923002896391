import {generateBlankConfig} from "../../McConfigPlugin";
import {useAppSelector} from "./redux";

function useMcConfig() {
    const {loading, data} = useAppSelector((state) => state.mcConfig);

    const config = data ? data : generateBlankConfig();

    return {config, loading};
}
export default useMcConfig;
