import {createAsyncThunk} from "@reduxjs/toolkit";
import {handleServiceCall} from "../../helpers/methods";
import GroupRosteringService from "../../../services/grs/GroupRosteringService";
import {CalendarEntryListRequest} from "../../../api/grs/code";

const getCalendarEntriesList = createAsyncThunk(
    "entry/get/all",
    async (args: CalendarEntryListRequest) => {
        return await handleServiceCall(
            GroupRosteringService.calendarApi.listCalendarEntries(args),
            "POST"
        );
    }
);

export {getCalendarEntriesList};
