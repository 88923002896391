import {StatusCode4xx} from "store-fetch-wrappers";
import history from "../../components/Navigation/Routes/history";
import {routeNames} from "../../components/Navigation/routeNames";

export function statusCodeCallback(code: StatusCode4xx) {
    switch (code) {
        case StatusCode4xx.FourZeroFour:
            history.replace(routeNames.notFound.path);
            return;
        case StatusCode4xx.FourZeroOne:
            history.replace(routeNames.notAuthorised.path);
            return;
        case StatusCode4xx.FourZeroThree:
            history.replace(routeNames.notAuthorised.path);
            return;
    }
}

export interface NonNullableStore<T> {
    data: NonNullable<T>;
    loading: boolean;
    error: string | null;
}
