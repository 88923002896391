import React, {Fragment, ReactNode} from "react";

function Card({children, className}: CardProps) {
    return (
        <Fragment>
            <div className={`mc-card ${className || ""}`}>{children}</div>
        </Fragment>
    );
}

function CardFooter({children, className}: CardInnerProps) {
    return (
        <Fragment>
            <div className="card-spacer" />
            <div className={`mc-card-wrapper ${className || ""}`}>{children}</div>
        </Fragment>
    );
}

function CardBody({children, className}: CardInnerProps) {
    return <div className={`mc-card-wrapper ${className || ""}`}>{children}</div>;
}

function CardHeader({children, className}: CardInnerProps) {
    return <div className={`mc-card-wrapper ${className || ""}`}>{children}</div>;
}

export {Card, CardFooter, CardBody, CardHeader};

interface CardProps {
    className?: string;
    children: ReactNode;
}

interface CardInnerProps {
    className?: string;
    children: ReactNode;
}
