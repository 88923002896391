import {useEffect, useState} from "react";
import {useAppSelector} from "../../../../../../components/Hooks/redux";

export const useFadeInFadeOut = ({fadeInFirst}: FadeInFadeOutOptions) => {
    const [fadeClassName, setFadeClassName] = useState<string>("");
    const sidebarToggled = useAppSelector((state) => state.sidebar.data) || false;
    useEffect(() => {
        if (fadeInFirst) {
            setFadeClassName(sidebarToggled ? "fadeIn" : "fadeOut");
            return;
        }
        setFadeClassName(sidebarToggled ? "fadeOut" : "fadeIn");
    }, [sidebarToggled]);

    return fadeClassName;
};

interface FadeInFadeOutOptions {
    fadeInFirst: boolean;
}
