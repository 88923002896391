import React, {useEffect, useState} from "react";
import Select, {SingleValue} from "react-select";
import {DDProps} from "../Helpers/dropdownUtils";
import {
    formatGroupLabel,
    getMetaDataDropdownOptions,
    GroupedOption
} from "./Helpers/metadataDropdownHelpers";
import {useAppSelector} from "../../Hooks/redux";
import {UserData} from "../../../api/staff/code";
import {CalendarEntry, Venue} from "../../../api/grs/code";

function MetadataDropdown(props: Props): JSX.Element {
    const calendarEntryListStore = useAppSelector((state) => state.calendarEntryList);
    const venueListListStore = useAppSelector((state) => state.venueList);
    const staffListStore = useAppSelector((state) => state.staffList);

    const [options, setOptions] = useState<GroupedOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<DDProps | undefined | null>();

    useEffect(() => {
        if (calendarEntryListStore.loading) return;
        if (staffListStore.loading) return;
        if (venueListListStore.loading) return;
        if (!calendarEntryListStore.data) return;
        if (!staffListStore.data) return;
        if (!venueListListStore.data) return;

        processDataIncoming(
            staffListStore.data,
            venueListListStore.data,
            calendarEntryListStore.data
        );
    }, [staffListStore, venueListListStore, calendarEntryListStore]);

    function processDataIncoming(staffList: UserData[], venues: Venue[], entries: CalendarEntry[]) {
        const ddOptions = getMetaDataDropdownOptions(staffList, venues, entries);

        setOptions(ddOptions);
    }

    /** Fired when a new option is selected */
    const handleOptionChanged = (newValue: SingleValue<DDProps>) => {
        setSelectedOption(newValue);
        props.onMetaChanged(newValue);
    };

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                onChange={handleOptionChanged}
                options={options}
                value={selectedOption}
                formatGroupLabel={formatGroupLabel}
                isSearchable={true}
                noOptionsMessage={() => "No Options Found"}
                menuPortalTarget={document.body}
                isDisabled={props.disabled}
                isClearable={props.clearable}
            />
        </React.Fragment>
    );
}

export default MetadataDropdown;

interface Props {
    clearable: boolean;
    disabled: boolean;
    initialValue: string;
    onMetaChanged: (value: DDProps | undefined | null) => void;
}
