import {createAsyncThunk} from "@reduxjs/toolkit";
import {handleServiceCall} from "../../helpers/methods";
import StaffManagementService from "../../../services/staff/StaffManagementService";
import {UserAbsenceConfigDefault} from "../../../api/staff/code";

const saveUserDefaultConfig = createAsyncThunk(
    "userconfig/default/save",
    async (config: UserAbsenceConfigDefault) => {
        return await handleServiceCall(
            StaffManagementService.absenceApi.setUserDefaultAbsenceConfig(config),
            "POST"
        );
    }
);

export {saveUserDefaultConfig};
