import {useEffect, useState} from "react";
import {SingleValue} from "react-select";
import {DropdownOption, ServiceDropdownSingle} from "../../Types/types";
import {useDropdownCore} from "./useDropdownCore";

export function useDropdownSingle<T>({
    storeName,
    storeKeyValue,
    storeKeyLabel,
    onChange,
    id,
    reduxDispatchClear,
    reduxDispatchFetch
}: ServiceDropdownSingle<T>) {
    const {options, reduxStore} = useDropdownCore({
        storeName,
        storeKeyValue,
        storeKeyLabel,
        reduxDispatchFetch,
        reduxDispatchClear
    });
    // const [options, setOptions] = useState<DropdownOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<DropdownOption | null>();

    useEffect(() => {
        if (!id) return;
        if (options.length === 0) return;

        const option = options.find((item) => item.value == id);

        setSelectedOption(option);
    }, [id, options]);

    function handleDropdownChange(newValue: SingleValue<DropdownOption>): void {
        setSelectedOption(newValue);
        const onChangeOption = getReduxStoreObjectFromDropdownOption(newValue);
        onChange(onChangeOption);
    }

    function getReduxStoreObjectFromDropdownOption(
        entity: SingleValue<DropdownOption>
    ): T | undefined | null {
        if (!entity) return;
        if (!reduxStore) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return reduxStore.data.find((item: T) => item[storeKeyValue] == entity.value);
    }

    return {
        options,
        selectedOption,
        handleDropdownChange
    };
}
