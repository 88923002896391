import React from "react";
import ErrorFieldMessage from "./FieldErrorMessage";
import {DebounceInput} from "react-debounce-input";

function Input({
    label,
    name,
    className,
    onChange,
    error,
    min,
    max,
    step,
    value,
    type,
    disabled,
    placeholder,
    withErrorPlaceholder,
    debounce
}: Props) {
    function getClassName() {
        const classes: string[] = ["input-fields"];

        if (className) classes.push(className);
        if (error?.length) classes.push("error");

        return classes.join(" ");
    }
    return (
        <div className={"input-wrapper"}>
            {label && (
                <label
                    data-testid="input-label"
                    className={`field-label ${error?.length ? "error" : ""}`}
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
            <div className="input-field-wrapper">
                {debounce ? (
                    <DebounceInput
                        debounceTimeout={300}
                        onChange={onChange}
                        disabled={disabled}
                        type={type}
                        min={min}
                        max={max}
                        step={step}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        className={getClassName()}
                    />
                ) : (
                    <input
                        onChange={onChange}
                        disabled={disabled}
                        type={type}
                        min={min}
                        max={max}
                        step={step}
                        id={name}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        className={getClassName()}
                    />
                )}
            </div>
            <ErrorFieldMessage error={error} withErrorPlaceholder={withErrorPlaceholder} />
        </div>
    );
}

export default Input;

interface Props {
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    label?: string;
    className?: string;
    error?: string;
    value?: string | number | (string[] & string) | (string[] & number);
    type?: React.HTMLInputTypeAttribute;
    min?: number;
    max?: number;
    step?: number;
    placeholder?: string;
    disabled?: boolean;
    withErrorPlaceholder?: boolean;
    debounce?: boolean;
}
