import React, {useEffect, useState} from "react";
import {DDProps} from "./Helpers/dropdownUtils";
import {getUiFriendlyText} from "../../utils/textUtils";
import Select, {SingleValue} from "react-select";

const Dropdown = ({
    clearable,
    options,
    initialValue,
    disabled,
    changeOption,
    name,
    label,
    error,
    withErrorPlaceholder
}: DropdownProps) => {
    const [selectedOption, setSelectedOption] = useState<DDProps>();

    useEffect(() => {
        const index = options.findIndex((item) => item.value === initialValue);

        setSelectedOption(index > -1 ? options[index] : undefined);
    }, [initialValue]);

    /** Fired when a new option is selected */
    const handleOptionChange = (option: SingleValue<DDProps>) => {
        if (!option) {
            changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        setSelectedOption(option);
        changeOption(option);
    };

    return (
        <React.Fragment>
            {label && (
                <label
                    data-testid="input-label"
                    className={`field-label ${error?.length ? "error" : ""}`}
                    htmlFor={name}
                >
                    {label}
                </label>
            )}
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={options}
                onChange={handleOptionChange}
                isSearchable={false}
                value={selectedOption}
                noOptionsMessage={() => "No Options Found"}
                isDisabled={disabled}
                isClearable={clearable}
                menuPortalTarget={document.body}
            />
            {withErrorPlaceholder && <div className="field-error-message-placeholder" />}
        </React.Fragment>
    );
};

export default Dropdown;

interface DropdownProps {
    initialValue: string | number | null;
    changeOption: (value: DDProps | undefined) => void;
    options: DDProps[];
    disabled?: boolean;
    clearable?: boolean;
    label?: string;
    name: string;
    error?: string;
    withErrorPlaceholder?: boolean;
}

function generateOptions(options: string[]): DDProps[] {
    return options.map((item) => {
        return {
            label: getUiFriendlyText(item),
            value: item
        };
    });
}
