import React, {useEffect} from "react";
import {Provider} from "react-redux";
import store from "./store/Store";
import Routes from "./components/Navigation/Routes/Routes";
import {Router} from "react-router-dom";
import history from "./components/Navigation/Routes/history";
import {ToastContainer} from "react-toastify";
import CacheBusterWrapper from "./components/CacheBuster/CacheBusterWrapper";
import {useAppDispatch} from "./components/Hooks/redux";
import {getMcConfig} from "./store/mcConfig/thunks/thunks";

const App = () => {
    return (
        <CacheBusterWrapper>
            <Provider store={store}>
                <RouteWrapper />
                <ToastContainer
                    position="bottom-center"
                    pauseOnHover
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    draggable
                    autoClose={5000}
                />
            </Provider>
        </CacheBusterWrapper>
    );
};

export default App;

function RouteWrapper() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getMcConfig());
    }, []);
    return (
        <Router history={history}>
            <Routes />
        </Router>
    );
}
