export const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SPECIAL_CHARACTER = /[-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#]/;

export const ENGLISH_UPPER_CASE = /[A-Z]/;

export const ENGLISH_LOWER_CASE = /[a-z]/;

export const NUMBER_REGEX = /[0-9]/;
