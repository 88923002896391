import React, {Fragment, useEffect, useState} from "react";
import {CardBody} from "../Cards/Card";
import Row from "../Grid/Row";
import Col from "../Grid/Col";
import {ClinicalGrade, StaffAccessLevel} from "../../api/staff/code";
import {DDProps, OptionsFromType} from "../Dropdown/Helpers/dropdownUtils";
import GenericTypeDropdown from "../Dropdown/GenericTypeDropdown";
import {
    getClinicalGradeFromString,
    getMfaTypeFromString,
    getStaffAccessLevelFromString,
    uiFriendlyClinicalGrades,
    uiFriendlyMfaTypes,
    uiFriendlyMfaTypesNoTotp
} from "../../utils/enumUtils";
import Toggle, {ToggleSize} from "../Toggle/Toggle";
import RegionMultiDropdown from "../Dropdown/RegionMultiDropdown";
import AccessDetail from "../AccessDetail/AccessDetail";
import {useUserData} from "../Hooks/useUserData";
import Flags from "../Flags/Flags";
import {StaffFormProps} from "./StaffForm";
import Dropdown from "../Dropdown/Dropdown";

function AdminDetails(props: StaffFormProps) {
    const [clinicalGrade, setClinicalGrade] = useState<string>(props.user.clinicalGrade);
    // const [mfaType, setMfaType] = useState<string>(props.user.favouredMfa || MfaType.None);
    const currentUser = useUserData();
    useEffect(() => {
        if (props.user.clinicalGrade === ClinicalGrade.EAC) {
            setClinicalGrade(uiFriendlyClinicalGrades.EAC);
            return;
        }

        setClinicalGrade(props.user.clinicalGrade);
    }, [props.user.clinicalGrade]);

    function getFavouredMfaOptions(): DDProps[] {
        const {user} = props;
        if (user.totp) {
            return Object.entries(uiFriendlyMfaTypes).map((entry) => {
                const [value, label] = entry;
                return {
                    value: value.toString(),
                    label
                };
            });
        }

        return Object.entries(uiFriendlyMfaTypesNoTotp).map((entry) => {
            const [value, label] = entry;
            return {
                value: value.toString(),
                label
            };
        });
    }
    return (
        <CardBody className="staff-form-body-wrapper">
            <div className="staff-form-body left">
                <p className="staff-form-section-label">Admin Details</p>
            </div>
            <div className="staff-form-body right">
                {currentUser.superAdmin && (
                    <Fragment>
                        <Row className="form-row-wrapper">
                            <Col>
                                <GenericTypeDropdown
                                    label="Access Level *"
                                    name="access-level-dropdown"
                                    enumOptions={StaffAccessLevel}
                                    splitByCapitalLetter={true}
                                    getOptionsFrom={OptionsFromType.Value}
                                    clearable={false}
                                    searchable={false}
                                    changeOption={(option) => {
                                        if (!option) return;
                                        const {user, onChange} = props;
                                        onChange({
                                            ...user,
                                            accessLevel: getStaffAccessLevelFromString(
                                                option.toString()
                                            )
                                        });
                                    }}
                                    id={props.user.accessLevel}
                                    disabled={false}
                                    searchWithDecapitalisedFirstLetter={false}
                                    withErrorPlaceholder
                                />
                            </Col>
                        </Row>
                        <Row className="form-row-wrapper">
                            <Col>
                                <Toggle
                                    label="Super Admin"
                                    checked={props.user.superAdmin}
                                    onChange={() => {
                                        const {user, onChange} = props;
                                        let value = user.superAdmin ? user.superAdmin : false;
                                        onChange({
                                            ...user,
                                            superAdmin: (value = !value)
                                        });
                                    }}
                                    size={ToggleSize.Large}
                                    withErrorPlaceholder
                                />
                            </Col>
                        </Row>
                        <Row className="form-row-wrapper">
                            <Col>
                                <AccessDetail {...props} />
                            </Col>
                        </Row>
                    </Fragment>
                )}
                <Row className="form-row-wrapper">
                    <Col>
                        <GenericTypeDropdown
                            label="Clinical Grade *"
                            name="clinical-grades-dropdown"
                            enumOptions={uiFriendlyClinicalGrades}
                            splitByCapitalLetter={true}
                            getOptionsFrom={OptionsFromType.Value}
                            clearable={false}
                            searchable={false}
                            changeOption={(option) => {
                                if (!option) return;
                                const {user, onChange} = props;
                                onChange({
                                    ...user,
                                    clinicalGrade: getClinicalGradeFromString(option.toString())
                                });
                            }}
                            id={clinicalGrade}
                            disabled={false}
                            searchWithDecapitalisedFirstLetter={false}
                            withErrorPlaceholder
                        />
                    </Col>
                </Row>
                <Row className="form-row-wrapper">
                    <Col>
                        <Flags {...props} />
                    </Col>
                </Row>
                <Row className="form-row-wrapper">
                    <Col>
                        <Toggle
                            label="Multi Factor Authentication (MFA)"
                            checked={props.user.mfa}
                            onChange={() => {
                                const {user, onChange} = props;
                                let value = user.mfa ? user.mfa : false;
                                onChange({
                                    ...user,
                                    mfa: (value = !value)
                                });
                            }}
                            size={ToggleSize.Large}
                            withErrorPlaceholder
                        />
                    </Col>
                    <Col>
                        <Toggle
                            label="Pay As You Earn (PAYE)"
                            checked={props.user.paye}
                            onChange={() => {
                                const {user, onChange} = props;
                                let value = user.paye ? user.paye : false;
                                onChange({
                                    ...user,
                                    paye: (value = !value)
                                });
                            }}
                            size={ToggleSize.Large}
                            withErrorPlaceholder
                        />
                    </Col>
                </Row>
                <Row className="form-row-wrapper">
                    <Col>
                        <Dropdown
                            label="Preferred Method of Multi Factor Authentication *"
                            name="totp dropdown"
                            options={getFavouredMfaOptions()}
                            changeOption={(option) => {
                                if (!option) return;
                                const {user, onChange} = props;
                                onChange({
                                    ...user,
                                    favouredMfa: getMfaTypeFromString(option?.value.toString())
                                });
                            }}
                            initialValue={props.user.favouredMfa || null}
                            withErrorPlaceholder
                        />
                    </Col>
                </Row>

                <Row className="form-row-wrapper">
                    <Col>
                        <RegionMultiDropdown
                            name="region-dropdown"
                            label="Location"
                            regionIds={props.user.regions || []}
                            onChange={(entity) => {
                                const ids = entity?.map((item) => item.id) || [];
                                const {user, onChange} = props;
                                onChange({
                                    ...user,
                                    regions: ids
                                });
                            }}
                            withErrorPlaceholder
                        />
                    </Col>
                </Row>
            </div>
        </CardBody>
    );
}

export default AdminDetails;
