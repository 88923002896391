import React, {useEffect, useState} from "react";
import {RequestFilterProps} from "../../../../components/Filters/helpers/filterHelpers";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../../components/Hooks/useQuery";
import GenericTypeDropdown from "../../../../components/Dropdown/GenericTypeDropdown";
import {OptionsFromType} from "../../../../components/Dropdown/Helpers/dropdownUtils";

function RegionListTableFilters(props: RequestFilterProps<RegionTableRequest>) {
    const history = useHistory();
    const query = useQuery();
    const [state, setState] = useState<RegionTableRequest>();

    useEffect(() => {
        const archived = query.get(RegionTableQuery.archive) as ArchiveFilter | null;

        updateRequest({archived: archived || ArchiveFilter.Unarchived});
    }, []);

    function updateRequest(newRequest: RegionTableRequest) {
        setState(newRequest);
        buildUrlHistory(newRequest);

        props.onRequestChanged(newRequest);
    }

    const buildUrlHistory = (request: RegionTableRequest) => {
        const queryStrings: string[] = [];
        if (request.archived) {
            queryStrings.push(`${RegionTableQuery.archive}=${request.archived}`);
        }

        history.push({
            search: `?${queryStrings.join("&")}`
        });
    };

    return (
        <div className="filter-inner-container-wrapper">
            <div className="filter-item">
                <h6>Archived</h6>
                {state && (
                    <GenericTypeDropdown
                        name="archived-dropdown"
                        enumOptions={ArchiveFilter}
                        splitByCapitalLetter={true}
                        getOptionsFrom={OptionsFromType.Value}
                        clearable={false}
                        searchable={false}
                        changeOption={(archived) => {
                            if (!archived) return;
                            updateRequest({
                                archived: archived.toString() as ArchiveFilter
                            });
                        }}
                        id={state?.archived}
                        disabled={false}
                        searchWithDecapitalisedFirstLetter={false}
                    />
                )}
            </div>
        </div>
    );
}

export default RegionListTableFilters;

export interface RegionTableRequest {
    archived: ArchiveFilter;
}

const RegionTableQuery = {
    archive: "archive"
};

// eslint-disable-next-line no-shadow
export enum ArchiveFilter {
    Archived = "Archived",
    Unarchived = "Unarchived",
    All = "All"
}
