import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {createSlice} from "@reduxjs/toolkit";
import {UserData} from "../../api/staff/code";
import {
    createNewUser,
    deleteExistingStaffMember,
    fetchStaffMember,
    resetPasswordByUserName,
    saveExistingStaffMember,
    saveNewStaffMember,
    setPasswordByUsername
} from "./thunks/thunks";
import {ReduxPayload} from "../helpers/types";

const initialState = createDefaultNullableStoreState<UserData>(null);

const staffSlice = createSlice({
    name: "staffList",
    initialState,
    reducers: {
        nullifyStaffStore(state) {
            state.data = null;
        },
        setExistingStaffMember(state, action: ReduxPayload<UserData>) {
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(createNewUser, builder);
        createBuildHandlers(fetchStaffMember, builder);
        createBuildHandlers(setPasswordByUsername, builder);
        createBuildHandlers(resetPasswordByUserName, builder);
        createBuildHandlers(saveExistingStaffMember, builder);
        createBuildHandlers(saveNewStaffMember, builder);
        createBuildHandlers(deleteExistingStaffMember, builder);
    }
});

export const {nullifyStaffStore, setExistingStaffMember} = staffSlice.actions;
export default staffSlice.reducer;
