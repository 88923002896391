import React from "react";
import {Modal, useModal} from "pulse-modal";
import Tooltip from "../Tooltip/Tooltip";
import Icon, {IconType} from "../Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {showSuccessToast} from "../../utils/toastUtils";
import {setExistingStaffMember} from "../../store/staff/staffSlice";
import {useAppDispatch} from "../Hooks/redux";
import {UserData} from "../../api/staff/code";
import {deepCopy} from "../../utils/sortingUtils";

function DeleteAccessDetail({message, accessDetailKey, user}: Props) {
    const {isShown, toggle} = useModal();
    const dispatch = useAppDispatch();

    function deleteAccessDetail() {
        const copy = deepCopy(user.accessDetail);
        delete copy![accessDetailKey];
        dispatch(
            setExistingStaffMember({
                ...user,
                accessDetail: copy
            })
        );

        showSuccessToast("Deleted custom access for this user");
    }
    return (
        <React.Fragment>
            <div className="icon-tooltip-wrapper-md mr-1">
                <Tooltip tooltipText={"Delete Access Detail"} size={"md"} place={"left"}>
                    <Icon
                        rootElement={"Div"}
                        icon={IconType.Bin}
                        size={"Medium"}
                        className="icon-dark"
                        onClick={toggle}
                    />
                </Tooltip>
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Delete Access Detail"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className="mb-0">{message}</p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <React.Fragment>
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={deleteAccessDetail}
                                colour={ButtonColourOptions.Yellow}
                                className="mr-3"
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                            />
                        </React.Fragment>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
}

export default DeleteAccessDetail;

interface Props {
    accessDetailKey: string;
    message: string;
    user: UserData;
}
