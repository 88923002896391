import {createAsyncThunk} from "@reduxjs/toolkit";
import {handleServiceCall} from "../../helpers/methods";
import StaffManagementService from "../../../services/staff/StaffManagementService";
import {StaffAccessLevel} from "../../../api/staff/code";

const getAllMedicareStaffMembers = createAsyncThunk("staff/get/all", async () => {
    return await handleServiceCall(StaffManagementService.usersApi.getAllUsers(), "POST");
});

const getStaffMembersByAccessLevel = createAsyncThunk(
    "staff/get/all-by-access-level",
    async (accessLevel: StaffAccessLevel) => {
        const data =
            (await handleServiceCall(StaffManagementService.usersApi.getAllUsers(), "POST")) || [];
        return data.filter((user) => user.accessLevel === accessLevel);
    }
);

export {getAllMedicareStaffMembers, getStaffMembersByAccessLevel};
