import React from "react";
import {NavLink} from "react-router-dom";
import {useAppDispatch} from "../../../../../components/Hooks/redux";
import {NavigationItem} from "../../../../../store/navigation/navigationSlice";
import {setSidebarToggled} from "../../../../../store/sidebar/sidebarSlice";

const SidebarInternalItem = ({children, path, name}: NavigationItem) => {
    const dispatch = useAppDispatch();
    const onRouteClicked = () => {
        dispatch(setSidebarToggled(false));
    };
    return (
        <React.Fragment>
            <li>
                {children ? (
                    <a className="sidebar-list-item-no-link group-item">
                        <p className="mb-0">{name}</p>
                    </a>
                ) : (
                    <NavLink
                        exact
                        strict
                        to={path || ""}
                        onClick={onRouteClicked}
                        className="sidebar-list-item group-item"
                        activeClassName="active-route"
                    >
                        <p className="mb-0">{name}</p>
                    </NavLink>
                )}
            </li>
            {children &&
                children.map((item, index) => {
                    return (
                        <li key={index}>
                            <NavLink
                                exact
                                strict
                                to={item.path || ""}
                                onClick={onRouteClicked}
                                className="sidebar-list-item group-item subsystem-group-item"
                                activeClassName="active-route"
                            >
                                <p className="mb-0">{item.name}</p>
                            </NavLink>
                        </li>
                    );
                })}
        </React.Fragment>
    );
};

export default SidebarInternalItem;
