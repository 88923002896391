export function toArray<T>(object: T) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Object.keys(object).map(function (index) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return object[index];
    });
}

export function deepCopy<T>(value: T): T {
    return JSON.parse(JSON.stringify(value));
}
