import React from "react";
import FormRow from "../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import CharacterLimitWarning from "../../Form/CharacterLimitWarning";
import {useAppDispatch, useAppSelector} from "../../Hooks/redux";
import {setSMSBody} from "../../../store/sms/smsSlice";

const SMSBody = () => {
    const smsStore = useAppSelector(
        (state) => state.sms.data || {staffMembers: [], bodyText: "", headerText: ""}
    );
    const dispatch = useAppDispatch();
    return (
        <React.Fragment>
            <FormRow rowName={"SMS Body"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    className={"input-fields text-area-inputs"}
                    element={"textarea"}
                    debounceTimeout={1}
                    placeholder={"Enter body here..."}
                    value={smsStore.bodyText}
                    onChange={(event) => {
                        const {value} = event.target;
                        dispatch(setSMSBody(value));
                    }}
                />
                <CharacterLimitWarning characterLimit={154} textValue={smsStore.bodyText} />
            </FormRow>
        </React.Fragment>
    );
};

export default SMSBody;
