import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import type {RootState, AppDispatch} from "../../store/Store";
import {AsyncThunkAction} from "@reduxjs/toolkit";
import {AsyncThunkConfig} from "@reduxjs/toolkit/dist/createAsyncThunk";
import {AsyncStoreDispatch} from "../../store/helpers/types";
import {showErrorToast} from "../../utils/toastUtils";
import {isDevelopment} from "../../utils/miscUtils";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useAsyncStoreDispatch() {
    const dispatch = useAppDispatch();
    async function handleNullableAsyncStoreDispatch<T>(
        thunk: AsyncThunkAction<T | null | undefined, T, AsyncThunkConfig>
    ) {
        // @ts-ignore
        const response: AsyncStoreDispatch<T> = await dispatch(thunk);

        if (response.error) {
            showErrorToast(response.error.message);
            if (isDevelopment()) showErrorToast(response.error.stack);
            return false;
        }

        return true;
    }
    async function handleNonNullableAsyncStoreDispatch<T>(
        thunk: AsyncThunkAction<T, any, AsyncThunkConfig>
    ) {
        // @ts-ignore
        const response: AsyncStoreDispatch<T> = await dispatch(thunk);

        if (response.error) {
            showErrorToast(response.error.message);
            if (isDevelopment()) showErrorToast(response.error.stack);
            return false;
        }

        return true;
    }

    return {
        handleNullableAsyncStoreDispatch,
        handleNonNullableAsyncStoreDispatch
    };
}
