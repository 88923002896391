import React from "react";
import {useRegionListContainer} from "./Hooks/useRegionListContainer";
import {WithServiceState} from "store-fetch-wrappers";
import RegionListTable from "./Components/RegionListTable";

const ServiceWrapper = WithServiceState(RegionListTable);

function RegionListContainer() {
    const {regionListStore} = useRegionListContainer();
    return (
        <div className="page-container">
            <ServiceWrapper
                loading={regionListStore.loading}
                error={regionListStore.error}
                data={regionListStore.data}
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                showLoadingText={true}
            />
        </div>
    );
}

export default RegionListContainer;
