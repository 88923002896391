import {createSlice} from "@reduxjs/toolkit";
import {createDefaultNullableStoreState} from "../helpers/methods";
import {ReduxPayload} from "../helpers/types";

export interface NavigationItem extends NavigationItemBase {
    children?: NavigationItemBase[];
}

export interface NavigationItemBase {
    name: string;
    path?: string;
}

const initialState = createDefaultNullableStoreState<NavigationItem[]>([]);

const navigationSlice = createSlice({
    name: "navigation",
    initialState,
    reducers: {
        setNavigationItems(state, action: ReduxPayload<NavigationItem[]>) {
            state.data = action.payload;
        }
    }
});

export const {setNavigationItems} = navigationSlice.actions;
export default navigationSlice.reducer;
