import React, {useEffect, useState} from "react";
import {PasswordResetFormProps} from "./AutoPasswordResetForm";
import {getValidPassword, PasswordCheck} from "../Helpers/staffListHelpers";
import Icon, {IconType} from "../../../components/Icon/Icon";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import {showErrorToast, showSuccessToast} from "../../../utils/toastUtils";
import {Loading} from "store-fetch-wrappers";
import {useAppSelector, useAsyncStoreDispatch} from "../../../components/Hooks/redux";
import {setPasswordByUsername} from "../../../store/staff/thunks/thunks";

const ManualPasswordResetForm = (props: PasswordResetFormProps) => {
    const [passwordCheck, setPasswordCheck] = useState<PasswordCheck>({
        isLowerCase: false,
        isUpperCase: false,
        isMoreThanEightCharacters: false,
        containsNumeric: false,
        containsSpecial: false
    });
    const [password, setPassword] = useState<string>("");
    const staffStore = useAppSelector((state) => state.staff);
    const {handleNonNullableAsyncStoreDispatch} = useAsyncStoreDispatch();

    const onPasswordUpdated = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    useEffect(() => {
        setPasswordCheck(getValidPassword(password));
    }, [password]);

    const resetPasswordManual = async () => {
        const {
            isLowerCase,
            isMoreThanEightCharacters,
            isUpperCase,
            containsNumeric,
            containsSpecial
        } = passwordCheck;

        if (
            !isLowerCase ||
            !isMoreThanEightCharacters ||
            !isUpperCase ||
            !containsNumeric ||
            !containsSpecial
        ) {
            showErrorToast("Password is not valid");
            return;
        }

        const success = await handleNonNullableAsyncStoreDispatch(
            setPasswordByUsername({userName: props.user.username, password})
        );

        if (success) {
            showSuccessToast(`Password reset for ${props.user.firstName} ${props.user.lastName}`);
            props.onClose();
        }
    };
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <p className="mb-0">
                    Enter new password for {props.user.firstName} {props.user.lastName}
                </p>
            </div>
            <div className="row ml-0 mr-0">
                <input
                    type="password"
                    value={password}
                    onChange={onPasswordUpdated}
                    className="input-fields"
                />
            </div>
            <div className="row ml-0 mt-3 mr-0">
                <p className="mb-0">Password must contain:</p>
            </div>
            <div className="row ml-0 mt-3 mr-0">
                <ul className="pl-3">
                    <li className={passwordCheck.isLowerCase ? "success-text" : "error-text"}>
                        A lower case letter{" "}
                        {passwordCheck.isLowerCase ? (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Success}
                                size={"Medium"}
                                className={"success-text"}
                            />
                        ) : (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Error}
                                size={"Large"}
                                className={"error-text"}
                            />
                        )}
                    </li>
                    <li className={passwordCheck.isUpperCase ? "success-text" : "error-text"}>
                        An upper case letter{" "}
                        {passwordCheck.isUpperCase ? (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Success}
                                size={"Medium"}
                                className={"success-text"}
                            />
                        ) : (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Error}
                                size={"Large"}
                                className={"error-text"}
                            />
                        )}
                    </li>
                    <li className={passwordCheck.containsSpecial ? "success-text" : "error-text"}>
                        A special character{" "}
                        {passwordCheck.containsSpecial ? (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Success}
                                size={"Medium"}
                                className={"success-text"}
                            />
                        ) : (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Error}
                                size={"Large"}
                                className={"error-text"}
                            />
                        )}
                    </li>
                    <li className={passwordCheck.containsNumeric ? "success-text" : "error-text"}>
                        A number{" "}
                        {passwordCheck.containsNumeric ? (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Success}
                                size={"Medium"}
                                className={"success-text"}
                            />
                        ) : (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Error}
                                size={"Large"}
                                className={"error-text"}
                            />
                        )}
                    </li>
                    <li
                        className={
                            passwordCheck.isMoreThanEightCharacters ? "success-text" : "error-text"
                        }
                    >
                        At least 8 characters{" "}
                        {passwordCheck.isMoreThanEightCharacters ? (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Success}
                                size={"Medium"}
                                className={"success-text"}
                            />
                        ) : (
                            <Icon
                                rootElement={"Span"}
                                icon={IconType.Error}
                                size={"Large"}
                                className={"error-text"}
                            />
                        )}
                    </li>
                </ul>
            </div>
            {staffStore.loading ? (
                <div className="row ml-0 mr-0">
                    <div className="col text-center">
                        <Loading type={"three-ring"} showLoadingText={false} />
                    </div>
                </div>
            ) : (
                <div className="row ml-0 mt-3 mr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={resetPasswordManual}
                        colour={ButtonColourOptions.Yellow}
                        className="mr-3"
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={props.onClose}
                        colour={ButtonColourOptions.DarkBlue}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default ManualPasswordResetForm;
