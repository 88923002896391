import React, {Fragment} from "react";
import Select from "react-select";
import {Region} from "../../api/staff/code";
import {BaseDropdownPropsMulti} from "./Types/types";
import {useDropdownMulti} from "./Hooks/useDropdown/useDropdownMulti";

function RegionMultiDropdown({regionIds, onChange, ...rest}: PulseRegionDropdown) {
    const {options, selectedOptions, handleDropdownChange} = useDropdownMulti<Region>({
        storeName: "regionList",
        onChange,
        ids: regionIds,
        storeKeyLabel: "name",
        storeKeyValue: "id"
    });
    return (
        <Fragment>
            {rest.label && (
                <label data-testid="input-label" className={`field-label`} htmlFor={rest.name}>
                    {rest.label}
                </label>
            )}
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={options}
                onChange={handleDropdownChange}
                isSearchable={rest.searchable}
                value={selectedOptions}
                noOptionsMessage={() => rest.noItemsMessage}
                placeholder={rest.placeholder}
                isDisabled={rest.disabled}
                isClearable={rest.clearable}
                menuPortalTarget={document.body}
                isMulti
            />
            {rest.withErrorPlaceholder && <div className="field-error-message-placeholder" />}
        </Fragment>
    );
}

export default RegionMultiDropdown;

export interface PulseRegionDropdown extends BaseDropdownPropsMulti<Region> {
    regionIds: number[];
    name: string;
    label?: string;
    withErrorPlaceholder?: boolean;
}
