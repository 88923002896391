import {createSlice} from "@reduxjs/toolkit";
import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {deleteUserYearConfig, saveUserYearConfig} from "./thunks/thunks";
import {UserAbsenceConfigYear} from "../../api/staff/code";

const initialState = createDefaultNullableStoreState<UserAbsenceConfigYear>(null);

const userAbsenceYearConfigSlice = createSlice({
    name: "userAbsenceYearConfig",
    initialState,
    reducers: {
        nullifyUserYearConfigStore(state) {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(saveUserYearConfig, builder);
        createBuildHandlers(deleteUserYearConfig, builder);
    }
});

export const {nullifyUserYearConfigStore} = userAbsenceYearConfigSlice.actions;
export default userAbsenceYearConfigSlice.reducer;
