import React, {useEffect} from "react";
import {CustomLoadingWheel} from "react-state-helpers";
import {useAppDispatch} from "../../../components/Hooks/redux";
import {setNavigationItems} from "../../../store/navigation/navigationSlice";
import {unAuthenticateUser} from "../../../store/auth/authSlice";
import {logout} from "../../../store/auth/thunks/thunks";

const Logout = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        localStorage.removeItem("current_route");
        dispatch(setNavigationItems([]));
        dispatch(unAuthenticateUser());
        setTimeout(() => {
            dispatch(logout());
        }, 1500);
    }, []);

    return (
        <React.Fragment>
            <div className="page background-stars">
                <h1 className="text-center title mt-5 pt-5 header-font">Signing You Out...</h1>
                <CustomLoadingWheel />
            </div>
        </React.Fragment>
    );
};

export default Logout;
