import React from "react";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../Table/NoItemsMessage";
import {processTag} from "../Helpers/smsHelpers";
import {SMSFormDetails} from "../../../store/sms/thunks/thunks";

const SMSStaffListTable = (props: SMSFormDetails) => {
    return (
        <React.Fragment>
            <PulseTable
                items={toTableRow(props)}
                headers={{staffName: "Staff Name", matchingTags: "Matching Tags"}}
                noItemsSection={
                    <NoItemsMessage
                        message={"There are no Staff Members that match the filters set"}
                    />
                }
                customRenderers={{
                    matchingTags: (item: TableRow) => item.matchingTags
                }}
            />
        </React.Fragment>
    );
};

export default SMSStaffListTable;

interface TableRow {
    staffName: string;
    matchingTags: JSX.Element;
}

function toTableRow(smsStore: SMSFormDetails): TableRow[] {
    return smsStore.staffMembers.map((item) => {
        return {
            staffName: item.staffName,
            matchingTags: getMatchingTags(item.selectedByTags)
        };
    });
}

function getMatchingTags(tags: string[]): JSX.Element {
    return (
        <React.Fragment>
            {tags.map((tag, index) => {
                return (
                    <p key={index} className="mb-0">
                        {processTag(tag)}
                    </p>
                );
            })}
        </React.Fragment>
    );
}
