import React from "react";
import FormRow from "../../../../components/Form/FormRow";
import FormActionContainer from "../../../../components/Form/FormActionContainer";
import {Loading} from "store-fetch-wrappers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../components/Button/MCButton";
import {useYearlyAbsenceForm} from "../../Hooks/useYearlyAbsenceForm";
import {UserAbsenceConfigYear} from "../../../../api/staff/code";

function YearlyAbsenceForm({yearConfig, onClose, isNewConfig}: Props) {
    const {config, updateConfig, saveYearlyConfig, userYearlyConfigStore} =
        useYearlyAbsenceForm(yearConfig);

    return (
        <React.Fragment>
            <FormRow rowName={"Year"} columnDetailClassName={isNewConfig ? "pl-0 pr-0" : ""}>
                {isNewConfig ? (
                    <input
                        value={config.year}
                        type={"number"}
                        onChange={(event) => {
                            const {value} = event.target;

                            updateConfig({
                                ...config,
                                year: +value
                            });
                        }}
                        className="input-fields"
                    />
                ) : (
                    <p className="mb-0">{config.year}</p>
                )}
            </FormRow>
            <FormRow rowName={"Holiday Limit"} columnDetailClassName={"pl-0 pr-0"}>
                <input
                    value={config.holidayLimit}
                    type={"number"}
                    onChange={(event) => {
                        const {value} = event.target;

                        updateConfig({
                            ...config,
                            holidayLimit: +value
                        });
                    }}
                    className="input-fields"
                />
            </FormRow>
            <FormActionContainer>
                {userYearlyConfigStore.loading ? (
                    <Loading type={"three-ring"} showLoadingText={false} />
                ) : (
                    <React.Fragment>
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Save"}
                            onClick={async () => {
                                const success = await saveYearlyConfig(isNewConfig);

                                if (!success) return;

                                onClose();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={onClose}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </React.Fragment>
                )}
            </FormActionContainer>
        </React.Fragment>
    );
}

export default YearlyAbsenceForm;

interface Props {
    yearConfig: UserAbsenceConfigYear;
    onClose: () => void;
    isNewConfig: boolean;
}
