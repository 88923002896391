import axios from "axios";
import {Auth} from "@aws-amplify/auth";
import {Amplify} from "aws-amplify";
import {Hub} from "@aws-amplify/core";
import store from "../Store";
import {fetchUserData} from "./thunks/thunks";
import {routeNames} from "../../components/Navigation/routeNames";
import history from "../../components/Navigation/Routes/history";

let lastError = 0;
let retryCount = 0;
interface AuthCredentials {
    jwtToken: string;
    username: string;
}

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const status = error.response ? error.response.status : null;
        if (status !== 401) throw error;

        // Auto clear retry count if last error was over 10 seconds ago
        const now = Math.round(new Date().getTime() / 1000);
        const delta = now - lastError;

        if (delta > 10000) retryCount = 0;
        lastError = now;

        // Only try a finite number of times
        if (++retryCount > 20) {
            // history.push(routeNames.inActive.path);
            throw error;
        }

        const creds = await handleUpdatedCredentials();
        if (creds && creds.jwtToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + creds.jwtToken;
            error.config.headers["Authorization"] = "Bearer " + creds.jwtToken;

            return await axios.request(error.config);
        } else {
            throw error;
        }
    }
);

export async function initAuth(config: any) {
    if (!config) throw Error("Auth config is missing or empty");

    Amplify.configure(config);

    Hub.listen("auth", (data) => {
        const {payload} = data;
        switch (payload.event) {
            case "signIn":
                handleUpdatedCredentials();
                break;
            case "oAuthSignOut":
                handleLoggedOut();
                break;
        }
    });

    await handleUpdatedCredentials();
}

export async function handleUpdatedCredentials(): Promise<AuthCredentials | null> {
    try {
        const user = await Auth.currentAuthenticatedUser();

        if (user && user.username) {
            const jwtToken = user.getSignInUserSession().getAccessToken().getJwtToken();

            axios.defaults.headers.common = {
                Authorization: "Bearer " + jwtToken
            };

            await store.dispatch(fetchUserData(user.username));

            return {
                jwtToken,
                username: user.username
            };
        } else {
            handleLoggedOut();
        }
    } catch (e) {
        goToSignIn();
    }

    return null;
}

function goToSignIn() {
    history.replace(routeNames.login.path);
}

function handleLoggedOut() {
    // Clear bearer auth and local state
    delete axios.defaults.headers.common["Authorization"];
}

export const RedirectQuery = {
    redirectUri: "redirect_uri"
};
