import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../Store";
import {getRegionListing} from "../../regions/list/thunks/thunks";
import {
    ClinicalGrade,
    MfaType,
    NewUser,
    SetPasswordRequest,
    StaffAccessLevel,
    UserData
} from "../../../api/staff/code";
import {handleServiceCall} from "../../helpers/methods";
import StaffManagementService from "../../../services/staff/StaffManagementService";
import {
    convertInternationalNumberToUk,
    convertUkNumberToInternational
} from "../../../utils/numberUtils";
import {showErrorToast} from "../../../utils/toastUtils";
import {EMAIL_REGEX} from "../../../utils/regexUtils";
import {maxTagLength} from "../../../components/SMS/Helpers/smsHelpers";
import GroupRosteringService from "../../../services/grs/GroupRosteringService";
import moment from "moment/moment";
import {deepCopy} from "../../../utils/sortingUtils";

const createNewUser = createAsyncThunk("staff/create", async () => {
    await store.dispatch(getRegionListing());

    return getEmptyUser();
});

const fetchStaffMember = createAsyncThunk("staff/get/byusername", async (username: string) => {
    await store.dispatch(getRegionListing());

    const user = await handleServiceCall(
        StaffManagementService.usersApi.getUserByUsername(username),
        "GET"
    );

    return user ? processStaffMemberIncoming(user) : getEmptyUser();
});

const deleteExistingStaffMember = createAsyncThunk(
    "staff/delete/byusername",
    async (username: string) => {
        const now = moment().startOf("day").unix();
        await handleServiceCall(
            StaffManagementService.usersApi.deleteUserByUsername(username),
            "DELETE"
        );
        await handleServiceCall(
            GroupRosteringService.calendarApi.removeUserFromFutureEvents({
                staffId: username,
                fromDate: now
            }),
            "DELETE"
        );

        return true;
    }
);

const saveNewStaffMember = createAsyncThunk("staff/save/new", async (user: UserData) => {
    const newUser: NewUser = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        accessLevel: user.accessLevel,
        clinicalGrade: user.clinicalGrade,
        postCode: user.postCode,
        mfa: user.mfa,
        flags: user.flags,
        paye: user.paye,
        jobTitle: user.jobTitle
    };

    const updatedUser: NewUser = processStaffMemberOutgoing(newUser);

    return await handleServiceCall(StaffManagementService.usersApi.addUser(updatedUser), "POST");
});

const saveExistingStaffMember = createAsyncThunk("staff/save/existing", async (user: UserData) => {
    // @ts-ignore
    const updatedUser: UserData = processStaffMemberOutgoing(user);

    return await handleServiceCall(StaffManagementService.usersApi.updateUser(updatedUser), "POST");
});

const setPasswordByUsername = createAsyncThunk(
    "staff/password/reset/manual",
    async (args: SetPasswordByUsernameArgs) => {
        const passwordArgs: SetPasswordRequest = {
            password: args.password,
            permanent: true
        };

        return await handleServiceCall(
            StaffManagementService.usersApi.setUserPasswordByUsername(args.userName, passwordArgs),
            "POST"
        );
    }
);

const resetPasswordByUserName = createAsyncThunk(
    "staff/password/reset/auto",
    async (username: string) => {
        return await handleServiceCall(
            StaffManagementService.usersApi.resetUserPasswordByUsername(username),
            "POST"
        );
    }
);

export {
    createNewUser,
    fetchStaffMember,
    setPasswordByUsername,
    resetPasswordByUserName,
    saveExistingStaffMember,
    saveNewStaffMember,
    deleteExistingStaffMember
};

function getEmptyUser(): UserData {
    return {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        accessLevel: StaffAccessLevel.Staff,
        clinicalGrade: ClinicalGrade.None,
        postCode: "",
        mfa: true,
        flags: [],
        paye: false,
        favouredMfa: MfaType.Sms,
        accessDetail: {},
        avatarUploaded: false,
        jobTitle: ""
    };
}

function processStaffMemberIncoming(user: UserData) {
    const copy = deepCopy(user);
    if (!copy.phoneNumber) return user;
    const number = copy.phoneNumber;

    return {
        ...copy,
        phoneNumber: convertInternationalNumberToUk(number),
        flags: copy.flags || []
    };
}

function processStaffMemberOutgoing(user: UserData | NewUser) {
    const copy = deepCopy(user);
    if (!copy.phoneNumber) return user;
    if (!copy.flags) return user;
    const number = user.phoneNumber;

    if (copy.nhsPin === "") copy.nhsPin = undefined;

    return {
        ...copy,
        phoneNumber: convertUkNumberToInternational(number),
        flags: processFlagsOutgoing(copy.flags)
    };
}

function processFlagsOutgoing(flags?: string[]) {
    if (!flags) return [];

    return flags.filter((flag) => flag.length > 0).map((flag) => flag.toUpperCase());
}

export function validateStaffMember(user: UserData): boolean {
    if (user.firstName.length === 0) {
        showErrorToast("First name cannot be blank");
        return false;
    }

    if (user.firstName.length < 3) {
        showErrorToast("First name must contain 3 characters");
        return false;
    }

    if (user.lastName.length === 0) {
        showErrorToast("Last name cannot be blank");
        return false;
    }

    if (user.lastName.length < 3) {
        showErrorToast("Last name must contain 3 characters");
        return false;
    }

    if (user.email.length === 0) {
        showErrorToast("Email cannot be blank");
        return false;
    }

    if (user.email.length === 0) {
        showErrorToast("Email cannot be blank");
        return false;
    }

    if (!EMAIL_REGEX.test(user.email)) {
        showErrorToast("Email is not valid");
        return false;
    }

    if (!user.phoneNumber) {
        showErrorToast("Phone number cannot be blank");
        return false;
    }

    if (user.phoneNumber && user.phoneNumber.length === 0) {
        showErrorToast("Phone number cannot be blank");
        return false;
    }

    if (user.phoneNumber && user.phoneNumber.length < 11) {
        showErrorToast("Phone number must be 11 digits");
        return false;
    }

    if (!user.postCode) {
        showErrorToast("Post code cannot be blank");
        return false;
    }

    if (user.postCode && user.postCode.length === 0) {
        showErrorToast("Post code cannot be blank");
        return false;
    }

    if (user.postCode && user.postCode.length < 2) {
        showErrorToast("Post code must contain at least 2 characters");
        return false;
    }

    for (const flag of user.flags || []) {
        if (flag.length > maxTagLength) {
            showErrorToast(
                `One or more flags are too long! Max length: 20. Actual length: ${flag.length}`
            );
            return false;
        }
    }

    return true;
}

export interface SetPasswordByUsernameArgs {
    userName: string;
    password: string;
}

export interface UserAccessDetail {
    [key: string]: StaffAccessLevel;
}
