import {createSlice} from "@reduxjs/toolkit";
import {Region} from "../../../api/staff/code";
import {createBuildHandlers, createDefaultNullableStoreState} from "../../helpers/methods";
import {getRegionListing} from "./thunks/thunks";

const initialState = createDefaultNullableStoreState<Region[]>([]);

const regionListSlice = createSlice({
    name: "regionList",
    initialState,
    reducers: {
        nullifyRegionListStore(state) {
            state.data = [];
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(getRegionListing, builder);
    }
});

export const {nullifyRegionListStore} = regionListSlice.actions;
export default regionListSlice.reducer;
