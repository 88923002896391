import {createSlice} from "@reduxjs/toolkit";
import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {generateBlankConfig, McConfig} from "../../McConfigPlugin";
// @ts-ignore
import {getMcConfig} from "./thunks/thunks";

const initialState = createDefaultNullableStoreState<McConfig>(generateBlankConfig());

const mcConfigSlice = createSlice({
    name: "mcConfig",
    initialState,
    reducers: {
        clearConfigStore(state) {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(getMcConfig, builder);
    }
});

export const {clearConfigStore} = mcConfigSlice.actions;
export default mcConfigSlice.reducer;
