import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import Icon, {IconType} from "../../../../../components/Icon/Icon";
import SubsystemChildRouteContainer from "./SubsystemChildRouteContainer";
import {NavigationItem} from "../../../../../store/navigation/navigationSlice";

const SubSystemHeaderItem = ({children, path, name}: NavigationItem) => {
    const [activeRoutes, setActiveRoutes] = useState<boolean[]>([]);
    const [isGroupRouteActive, setisGroupRouteActive] = useState<boolean>(false);

    useEffect(() => {
        return function () {
            setisGroupRouteActive(false);
            setActiveRoutes([]);
        };
    }, []);

    const onActiveRoute = (value: boolean) => {
        setActiveRoutes((state) => {
            state.push(value);
            return state;
        });
    };

    useEffect(() => {
        for (const active of activeRoutes) {
            if (active) {
                setisGroupRouteActive(active);
                return;
            }
        }
    }, [activeRoutes]);

    return (
        <React.Fragment>
            <li className={`nav-links ${isGroupRouteActive ? "current-system" : ""}`}>
                {children ? (
                    <p className="mb-0 header-font">
                        {name}
                        <Icon
                            rootElement={"Span"}
                            icon={IconType.AngleDown}
                            className={`nav-links ${isGroupRouteActive ? "current-system" : ""}`}
                            size={"Small"}
                        />
                    </p>
                ) : (
                    <NavLink
                        exact
                        strict
                        to={path || ""}
                        className="nav-links"
                        activeClassName="current-system"
                    >
                        <p className="mb-0 header-font">{name}</p>
                    </NavLink>
                )}
                {children && children.length > 0 && (
                    <div className="subsystem-nav-dropdown-content">
                        <SubsystemChildRouteContainer
                            navItem={{children, path, name}}
                            onActiveRoute={onActiveRoute}
                        />
                    </div>
                )}
            </li>
        </React.Fragment>
    );
};

export default SubSystemHeaderItem;
