import {CalendarApi, ReportsApi, StaffApi, VenueApi} from "../../api/grs/code";
export default class GroupRosteringService {
    private static instance: GroupRosteringService;
    private static _calendarApi: CalendarApi;
    private static _reportsApi: ReportsApi;
    private static _staffApi: StaffApi;
    private static _venueApi: VenueApi;

    static get getInstance(): GroupRosteringService {
        if (!GroupRosteringService.instance) {
            GroupRosteringService.instance = new GroupRosteringService();
        }

        return GroupRosteringService.instance;
    }

    static get calendarApi(): CalendarApi {
        if (!GroupRosteringService._calendarApi) {
            GroupRosteringService._calendarApi = new CalendarApi({
                basePath: process.env.REACT_APP_GRS_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return GroupRosteringService._calendarApi;
    }

    static get reportsApi(): ReportsApi {
        if (!GroupRosteringService._reportsApi) {
            GroupRosteringService._reportsApi = new ReportsApi({
                basePath: process.env.REACT_APP_GRS_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return GroupRosteringService._reportsApi;
    }

    static get staffApi(): StaffApi {
        if (!GroupRosteringService._staffApi) {
            GroupRosteringService._staffApi = new StaffApi({
                basePath: process.env.REACT_APP_GRS_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return GroupRosteringService._staffApi;
    }

    static get venueApi(): VenueApi {
        if (!GroupRosteringService._venueApi) {
            GroupRosteringService._venueApi = new VenueApi({
                basePath: process.env.REACT_APP_GRS_API,
                isJsonMime(): boolean {
                    return false;
                }
            });
        }

        return GroupRosteringService._venueApi;
    }
}
