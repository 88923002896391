import React from "react";
import {useAbsenceConfigPage} from "./Hooks/useAbsenceConfigPage";
import EditUserAbsenceConfigForm from "./Components/EditUserAbsenceConfigForm";
import {WithServiceState} from "store-fetch-wrappers";

const ServiceWrapper = WithServiceState(EditUserAbsenceConfigForm);

function AbsenceConfig() {
    const {userConfigStore} = useAbsenceConfigPage();
    return (
        <React.Fragment>
            <div className="page-container mt-3">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    {...userConfigStore}
                />
            </div>
        </React.Fragment>
    );
}

export default AbsenceConfig;
