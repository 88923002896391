import React from "react";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "./MCButton";
import Icon, {IconType} from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";

/** Local action button. Contains a modal which will appear upon clicking. Confirmation action performed on confirm */
const ActionButtonConfirmation = (props: LocalDeleteActionButtonProps) => {
    const {isShown, toggle} = useModal();

    const onConfirm = () => {
        props.action();
        toggle();
    };

    return (
        <React.Fragment>
            {props.iconButton === IconButton.Button && (
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={props.buttonText || ""}
                    onClick={toggle}
                    colour={props.buttonColour || ButtonColourOptions.Yellow}
                    roundedCorner
                />
            )}
            {props.iconButton === IconButton.Icon && (
                <div className="icon-tooltip-wrapper-md mr-2">
                    <Tooltip tooltipText={props.tooltipText || ""} size={"md"} place={"left"}>
                        <Icon
                            rootElement={"Div"}
                            icon={props.icon || IconType.Edit}
                            size={"Medium"}
                            className="icon-dark"
                            onClick={toggle}
                        />
                    </Tooltip>
                </div>
            )}
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={props.modalTitle}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p>{props.modalText}</p>
                        </div>
                    </React.Fragment>
                }
                footerChildren={
                    <React.Fragment>
                        <div className="row mt-3 ml-0 mr-0">
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"Yes"}
                                onClick={onConfirm}
                                colour={ButtonColourOptions.Yellow}
                                roundedCorner
                            />
                            <MCButton
                                size={ButtonSize.Large}
                                innerValue={"No"}
                                onClick={toggle}
                                colour={ButtonColourOptions.DarkBlue}
                                roundedCorner
                            />
                        </div>
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default ActionButtonConfirmation;

interface LocalDeleteActionButtonProps {
    buttonText?: string;
    action: () => void;
    buttonColour?: ButtonColourOptions;
    modalText: string;
    modalTitle: string;
    tooltipText?: string;
    icon?: IconType;
    iconButton: IconButton;
}

// eslint-disable-next-line no-shadow
export enum IconButton {
    // eslint-disable-next-line no-shadow
    Icon = "Icon",
    Button = "Button"
}
