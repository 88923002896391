import {createSlice} from "@reduxjs/toolkit";
import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {CalendarEntry} from "../../api/grs/code";
import {getCalendarEntriesList} from "./thunks/thunks";

const initialState = createDefaultNullableStoreState<CalendarEntry[]>([]);

const calendarEntryListSlice = createSlice({
    name: "calendarEntryList",
    initialState,
    reducers: {
        nullifyCalendarEntryListStore(state) {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(getCalendarEntriesList, builder);
    }
});

export const {nullifyCalendarEntryListStore} = calendarEntryListSlice.actions;
export default calendarEntryListSlice.reducer;
