import React from "react";
import DefaultConfig from "./DefaultConfig";
import FormHeader from "../../../components/Form/FormHeader";
import {useEditUserAbsenceConfigForm} from "../Hooks/useEditUserAbsenceConfigForm";
import FormActionContainer from "../../../components/Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import YearlyConfigTable from "./YearConfigTable/YearlyConfigTable";
import {UserAbsenceConfig} from "../../../api/staff/code";

function EditUserAbsenceConfigForm(props: UserAbsenceConfig) {
    const {fullName, backToStaffDatabase} = useEditUserAbsenceConfigForm();
    return (
        <React.Fragment>
            <FormHeader headerName={`Annual Leave Config for ${fullName}`} />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <DefaultConfig {...props["default"]} />
            <YearlyConfigTable {...props.years} />

            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Back to Staff Database"}
                    onClick={backToStaffDatabase}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
}

export default EditUserAbsenceConfigForm;
