import React, {useEffect} from "react";
import {routeNames} from "../../components/Navigation/routeNames";
import SMSForm from "../../components/SMS/Components/SMSForm";
import FormHeader from "../../components/Form/FormHeader";
import {useAppDispatch} from "../../components/Hooks/redux";
import {NavigationItem, setNavigationItems} from "../../store/navigation/navigationSlice";
import {getAllMedicareStaffMembers} from "../../store/staffList/thunks/thunks";

const StaffMessenger = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.staffDatabase.name,
                path: routeNames.staffDatabase.path
            },
            {
                name: routeNames.staffMessengerLogs.name,
                path: routeNames.staffMessengerLogs.path
            },
            {
                name: routeNames.regionList.name,
                path: routeNames.regionList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(getAllMedicareStaffMembers());
    }, []);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <FormHeader headerName={"Send Staff SMS"} />
                <SMSForm />
            </div>
        </React.Fragment>
    );
};

export default StaffMessenger;
