import {
    ENGLISH_LOWER_CASE,
    ENGLISH_UPPER_CASE,
    NUMBER_REGEX,
    SPECIAL_CHARACTER
} from "../../../utils/regexUtils";

export function getValidPassword(password: string): PasswordCheck {
    const newPassword = password.trim();
    if (newPassword.length === 0) {
        return {
            isLowerCase: false,
            isUpperCase: false,
            isMoreThanEightCharacters: false,
            containsNumeric: false,
            containsSpecial: false
        };
    }

    return {
        isLowerCase: ENGLISH_LOWER_CASE.test(newPassword),
        isUpperCase: ENGLISH_UPPER_CASE.test(newPassword),
        isMoreThanEightCharacters: newPassword.length >= 8,
        containsNumeric: NUMBER_REGEX.test(newPassword),
        containsSpecial: SPECIAL_CHARACTER.test(newPassword)
    };
}

export interface PasswordCheck {
    isLowerCase: boolean;
    isUpperCase: boolean;
    containsSpecial: boolean;
    containsNumeric: boolean;
    isMoreThanEightCharacters: boolean;
}
