import React, {Fragment} from "react";
import Row from "../Grid/Row";
import Col from "../Grid/Col";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {StaffFormProps} from "../StaffForm/StaffForm";
import UserFlag from "../../pages/StaffMember/Components/UserFlag";

function Flags({user, onChange}: StaffFormProps) {
    return (
        <Fragment>
            <Row>
                <Col>
                    <label className="field-label">Flags</label>
                </Col>
                <Col className="d-flex justify-content-end">
                    <MCButton
                        size={ButtonSize.Large}
                        roundedCorner
                        innerValue={"Add Flag"}
                        onClick={() => {
                            onChange({
                                ...user,
                                flags: [...(user.flags || []), ""]
                            });
                        }}
                        colour={ButtonColourOptions.Default}
                    />
                </Col>
            </Row>
            {(user.flags || []).map((flag: string, index: number) => {
                return <UserFlag user={user} flag={flag} index={index} key={index} />;
            })}
            <div className="field-error-message-placeholder" />
        </Fragment>
    );
}

export default Flags;
