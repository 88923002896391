import React from "react";
import {toArray} from "../../../../../utils/sortingUtils";
import {GroupSubSystem} from "./Helpers/topbarHelpers";
import NavigationDropdownItem from "./NavigationDropdownItem";

const NavigationDropdownContainer = (props: GroupSubSystem[]) => {
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                {toArray(props).map((item: GroupSubSystem, index: number) => {
                    return <NavigationDropdownItem {...item} key={index} />;
                })}
            </div>
        </React.Fragment>
    );
};

export default NavigationDropdownContainer;
