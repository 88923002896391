import {ClinicalGrade, StaffAccessLevel, UserData} from "../../api/staff/code";
import {useAppSelector} from "./redux";
export const useUserData = () => {
    const user: UserData = {
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        accessLevel: StaffAccessLevel.Staff,
        clinicalGrade: ClinicalGrade.None,
        paye: false,
        avatarUploaded: false,
        jobTitle: ""
    };

    const authStore = useAppSelector((state) => state.auth);

    const userFromStore = authStore.data;

    if (userFromStore) {
        return userFromStore;
    }

    return user;
};
