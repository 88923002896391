import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import {useAppDispatch} from "../../../components/Hooks/redux";
import {login} from "../../../store/auth/thunks/thunks";

const InActive = () => {
    const dispatch = useAppDispatch();

    const loginToSystem = () => {
        dispatch(login());
    };

    return (
        <div className="page background-stars">
            <h3 className="text-center title mt-5 pt-5 header-font">
                You have been signed out due to inactivity...
            </h3>
            <div className="mt-5 text-center body-font">
                <p>To sign back in, click the button below</p>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue="Click here"
                    className="mt-4"
                    onClick={loginToSystem}
                    colour={ButtonColourOptions.Yellow}
                />
            </div>
        </div>
    );
};

export default InActive;
