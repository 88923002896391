import {createSlice} from "@reduxjs/toolkit";
import {Region} from "../../../api/staff/code";
import {createBuildHandlers, createDefaultNullableStoreState} from "../../helpers/methods";
import {getRegion, saveRegion} from "./thunks/thunks";
import {ReduxPayload} from "../../helpers/types";

const initialState = createDefaultNullableStoreState<Region>(null);

const regionSlice = createSlice({
    name: "region",
    initialState,
    reducers: {
        nullifyRegionStore(state) {
            state.data = null;
        },
        createRegion(state) {
            state.data = {
                id: 0,
                name: "",
                postcode: "",
                archived: false
            };
        },
        updateRegion(state, action: ReduxPayload<Region>) {
            state.data = action.payload;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(getRegion, builder);
        createBuildHandlers(saveRegion, builder);
    }
});

export const {nullifyRegionStore, updateRegion, createRegion} = regionSlice.actions;
export default regionSlice.reducer;
