import {routeNames} from "../Navigation/routeNames";
import {useAppDispatch} from "./redux";
import {NavigationItem, setNavigationItems} from "../../store/navigation/navigationSlice";

export function useRouteSetup() {
    const dispatch = useAppDispatch();

    function initialiseRoutes() {
        const routes = getRoutes();

        dispatch(setNavigationItems(routes));
    }

    function getRoutes(): NavigationItem[] {
        return [
            {
                name: routeNames.staffDatabase.name,
                path: routeNames.staffDatabase.path
            },
            {
                name: routeNames.staffMessengerLogs.name,
                path: routeNames.staffMessengerLogs.path
            }
        ];
    }

    return {
        initialiseRoutes
    };
}
