import React from "react";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";

import {showSuccessToast} from "../../../utils/toastUtils";
import {Loading} from "store-fetch-wrappers";
import {useAppSelector, useAsyncStoreDispatch} from "../../../components/Hooks/redux";
import {resetPasswordByUserName} from "../../../store/staff/thunks/thunks";
import {UserData} from "../../../api/staff/code";

const AutoPasswordResetForm = (props: PasswordResetFormProps) => {
    const staffStore = useAppSelector((state) => state.staff);
    const {handleNonNullableAsyncStoreDispatch} = useAsyncStoreDispatch();

    const resetPasswordAuto = async () => {
        const success: boolean = await handleNonNullableAsyncStoreDispatch(
            resetPasswordByUserName(props.user.username)
        );

        if (success) {
            showSuccessToast(`Password reset for ${props.user.firstName} ${props.user.lastName}`);
            props.onClose();
        }
    };
    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <p className="mb-0">
                    Are you sure you want to reset password for {props.user.firstName}{" "}
                    {props.user.lastName}?
                </p>
            </div>
            {staffStore.loading ? (
                <div className="row ml-0 mr-0">
                    <div className="col text-center">
                        <Loading type={"three-ring"} showLoadingText={false} />
                    </div>
                </div>
            ) : (
                <div className="row ml-0 mt-3 mr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Yes"}
                        onClick={resetPasswordAuto}
                        colour={ButtonColourOptions.Yellow}
                        className="mr-3"
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"No"}
                        onClick={props.onClose}
                        colour={ButtonColourOptions.DarkBlue}
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default AutoPasswordResetForm;

export interface PasswordResetFormProps {
    onClose: () => void;
    user: UserData;
}
