import React, {createRef, useEffect, useState} from "react";
import {ContainerStyle, getContainerStyle, TopBarItem} from "./Helpers/topbarHelpers";
import Icon, {IconType} from "../../../../../components/Icon/Icon";
import NavigationDropdownContainer from "./NavigationDropdownContainer";
import useCanUserSeeLink from "./Hooks/useCanUserSeeLink";
import useWindowSize from "../../../../../components/Hooks/useWindowResize";
import {useCorrectSystemLink} from "../Hooks/useCorrectSystemLink";

const TopbarItem = (props: TopBarItem) => {
    const listItem = createRef<HTMLLIElement>();
    const [style, setStyle] = useState<ContainerStyle>({right: "0", width: "0"});
    const showLink = useCanUserSeeLink(props);
    const size = useWindowSize();
    const link = useCorrectSystemLink(props);

    useEffect(() => {
        if (!listItem.current) return;
        setStyle(getContainerStyle(listItem.current));
    }, [showLink, size]);

    return (
        <React.Fragment>
            {showLink && (
                <li
                    ref={listItem}
                    className={`nav-links ${props.isActive ? "current-system" : ""}`}
                >
                    <a
                        className={`nav-links ${props.isActive ? "current-system" : ""}`}
                        href={link}
                    >
                        {props.groupName}
                    </a>
                    {props.subsystems && props.subsystems.length > 0 && (
                        <Icon
                            rootElement={"Span"}
                            icon={IconType.AngleDown}
                            className={`nav-links mt-1 ${props.isActive ? "current-system" : ""}`}
                            size={"Small"}
                        />
                    )}
                    {props.subsystems && props.subsystems.length > 0 && (
                        <div className="top-nav-dropdown-content" style={style}>
                            <NavigationDropdownContainer {...props.subsystems} />
                        </div>
                    )}
                </li>
            )}
        </React.Fragment>
    );
};

export default TopbarItem;
