import {createBuildHandlers, createDefaultNullableStoreState} from "../helpers/methods";
import {createSlice} from "@reduxjs/toolkit";
import {SmsHistoryResponse} from "../../api/staff/code";
import {getDataForSmsLogFiltersWithBlankTable, getSmsLogsList} from "./thunks/thunks";

const initialState = createDefaultNullableStoreState<SmsHistoryResponse>({
    entries: [],
    totalEntries: 0,
    pageNum: 0,
    numPerPage: 50,
    metadata: {}
});

const smsLogsSlice = createSlice({
    name: "smsLogs",
    initialState,
    reducers: {
        nullifySmsLogsStore(state) {
            state.data = null;
        }
    },
    extraReducers: (builder) => {
        createBuildHandlers(getDataForSmsLogFiltersWithBlankTable, builder);
        createBuildHandlers(getSmsLogsList, builder);
    }
});

export const {nullifySmsLogsStore} = smsLogsSlice.actions;
export default smsLogsSlice.reducer;
