import React from "react";

const CharacterLimitWarning = ({characterLimit, textValue}: Props) => {
    const getClassName = () => {
        if (textValue.length >= characterLimit) {
            return "disallowed-text";
        }
        return "allowed-text";
    };
    const getCharactersRemaining = () => {
        const remaining = characterLimit - textValue.length;

        return `${remaining} characters remaining`;
    };
    return (
        <React.Fragment>
            <p className={`${getClassName()} pl-2 mb-0 mt-1`}>{getCharactersRemaining()}</p>
        </React.Fragment>
    );
};

export default CharacterLimitWarning;

interface Props {
    characterLimit: number;
    textValue: string;
}
