import React, {useState} from "react";
import {formatUnixToDDMMYYYYHHmmss} from "../../../utils/momentUtils";
import {PulseTableWithServerPagination} from "pulse_table";
import {pagedRequestConfig} from "../../../utils/filterUtils";
import NoItemsMessage from "../../../components/Table/NoItemsMessage";
import FilterContainer from "../../../components/Filters/FilterContainer";
import StaffMessengerLogsFilters from "./StaffMessengerLogsFilters";
import {capitalizeFirstLetter} from "../../../utils/textUtils";
import StaffMessengerLogsActions from "./StaffMessengerLogsActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../components/Navigation/routeNames";
import {SmsHistory, SmsHistoryRequest, SmsHistoryResponse, UserData} from "../../../api/staff/code";
import {useAppDispatch, useAppSelector} from "../../../components/Hooks/redux";
import {getSmsLogsList} from "../../../store/smsLogs/thunks/thunks";

function StaffMessengerLogsTable(props: SmsHistoryResponse): JSX.Element {
    const staffList = useAppSelector((state) => state.staffList.data || []);
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [smsLogsRequest, setSmsLogsRequest] = useState<SmsHistoryRequest>();

    function onRequestChanged(request: SmsHistoryRequest) {
        //Ensures service isn't hammered with requests because of state updating
        if (JSON.stringify(request) === JSON.stringify(smsLogsRequest)) return;

        fetchPagedSmsLogs(request);
        setSmsLogsRequest(request);
    }

    // Fetches Audits
    function fetchPagedSmsLogs(request: SmsHistoryRequest) {
        dispatch(getSmsLogsList(request));
    }

    function createNewSms() {
        history.push(routeNames.staffMessenger.path);
    }

    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <StaffMessengerLogsFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <div className="row mt-5 ml-0 mr-0">
                <div className="col d-flex justify-content-end pr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Create New Staff Message"}
                        onClick={createNewSms}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                </div>
            </div>
            <PulseTableWithServerPagination
                resultsPerPage={pagedRequestConfig.resultsPerPage}
                totalResults={props.totalEntries}
                onPageChanged={(newPageNum) => {
                    if (!smsLogsRequest) return;
                    fetchPagedSmsLogs({
                        ...smsLogsRequest,
                        pageNum: newPageNum
                    });
                }}
                customRenderers={{
                    metaData: (item: StaffMessengerLogsTableRow) => item.metaData,
                    actions: (item: StaffMessengerLogsTableRow) => (
                        <StaffMessengerLogsActions {...item} />
                    )
                }}
                items={toTableRow(props, staffList)}
                headers={{
                    date: "Date",
                    message: "Message",
                    sender: "Sender",
                    metaData: "Meta Data",
                    actions: "Actions"
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no logs matching these filters"} />
                }
            />
        </React.Fragment>
    );
}

export default StaffMessengerLogsTable;

export interface StaffMessengerLogsTableRow {
    date: string;
    message: string;
    sender: string;
    metaData: JSX.Element;
    actions: SmsHistory;
}

function toTableRow(resp: SmsHistoryResponse, staffList: UserData[]): StaffMessengerLogsTableRow[] {
    return resp.entries.map((item) => {
        return {
            date: formatUnixToDDMMYYYYHHmmss(item.date),
            message: item.message,
            sender: getSender(item.username, staffList),
            metaData: getMetaData(item.metadata),
            actions: item
        };
    });
}

function getSender(senderUsername: string, staffList: UserData[]): string {
    const sender = staffList.find((item) => item.username === senderUsername);

    if (!sender) return "Deleted User";

    return getFullName(sender);
}

function getFullName({firstName, lastName}: UserData): string {
    return `${firstName} ${lastName}`;
}

function getMetaData(metaData: {[p: string]: string}): JSX.Element {
    return (
        <React.Fragment>
            {Object.values(metaData).map((value, index) => {
                return (
                    <p key={index} className="mb-0">
                        {decipherMetaDataValue(value)}
                    </p>
                );
            })}
        </React.Fragment>
    );
}

export function decipherMetaDataValue(value: string): string {
    switch (value) {
        case "eac":
            return "Emergency Ambulance Crew";
        case "firstresponder":
            return "First Responder";
        default:
            return capitalizeFirstLetter(value);
    }
}
