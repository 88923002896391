import React, {useEffect, useState} from "react";
import {getUiFriendlyText} from "../../../utils/textUtils";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Icon, {IconType} from "../../../components/Icon/Icon";
import ResetPasswordButton from "./ResetPasswordButton";
import DeleteStaffMemberButton from "./DeleteStaffMemberButton";
import {routeNames} from "../../../components/Navigation/routeNames";
import {PulseTableWithLocalPagination} from "pulse_table";
import {useHistory} from "react-router-dom";
import {toArray} from "../../../utils/sortingUtils";
import {pagedRequestConfig} from "../../../utils/filterUtils";
import {convertInternationalNumberToUk} from "../../../utils/numberUtils";
import FilterContainer from "../../../components/Filters/FilterContainer";
import StaffDatabaseFilters, {
    StaffDatabaseRequest,
    uiFilterFriendlyClinicalGrades
} from "./StaffDatabaseFilters";
import {usePageUrl} from "../../../components/Hooks/usePageUrl";
import {getClinicalGradeFromString} from "../../../utils/enumUtils";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import {ClinicalGrade, UserData} from "../../../api/staff/code";

const StaffListTable = (props: UserData[]) => {
    const history = useHistory();
    const [request, setRequest] = useState<StaffDatabaseRequest>();
    const [items, setItems] = useState<TableRow[]>([]);
    const {searchQueries} = usePageUrl();

    useEffect(() => {
        if (!request) return;

        const filteredResults = getFilteredStaffTableResults(
            toArray(props),
            request,
            history,
            searchQueries
        );
        setItems(filteredResults);
    }, [request, props]);

    const onRequestChanged = (incomingRequest: StaffDatabaseRequest) => {
        if (JSON.stringify(incomingRequest) === JSON.stringify(request)) return;
        setRequest(incomingRequest);
    };

    const addStaffMember = () => {
        history.push(routeNames.addStaff.path);
    };

    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <StaffDatabaseFilters onRequestChanged={onRequestChanged} />
            </FilterContainer>
            <div className="row mt-5 ml-0 mr-0">
                <div className="col d-flex justify-content-end pr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Add Staff Member"}
                        onClick={addStaffMember}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                </div>
            </div>
            <PulseTableWithLocalPagination
                resultsPerPage={pagedRequestConfig.resultsPerPage}
                items={items}
                headers={{
                    name: "Name",
                    accessLevel: "Access Level",
                    clinicalGrade: "Clinical Grade",
                    phoneNumber: "Phone Number",
                    superAdmin: "Super Admin",
                    mfa: "MFA",
                    paye: "PAYE",
                    actions: "Actions"
                }}
                customRenderers={{
                    superAdmin: (item: TableRow) => (
                        <React.Fragment>{item.superAdmin}</React.Fragment>
                    ),
                    mfa: (item: TableRow) => <React.Fragment>{item.mfa}</React.Fragment>,
                    paye: (item: TableRow) => <React.Fragment>{item.paye}</React.Fragment>,
                    actions: (item: TableRow) => <React.Fragment>{item.actions}</React.Fragment>
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0 fadeIn">
                        <div className="col">
                            <h5 className="text-center p-3">
                                There are no staff members available
                            </h5>
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default StaffListTable;

interface TableRow {
    name: string;
    accessLevel: string;
    clinicalGrade: string;
    phoneNumber: string;
    superAdmin: JSX.Element | JSX.Element[];
    mfa: JSX.Element | JSX.Element[];
    paye: JSX.Element | JSX.Element[];
    actions: JSX.Element | JSX.Element[];
}

function staffListToTableRow(staffList: UserData[], history: any, search: string): TableRow[] {
    return staffList.map((item) => {
        return {
            name: `${item.firstName} ${item.lastName}`,
            accessLevel: getUiFriendlyText(item.accessLevel),
            clinicalGrade: getClinicalGrade(item.clinicalGrade),
            phoneNumber: convertInternationalNumberToUk(item.phoneNumber || ""),
            superAdmin: (
                <div className="mfa-base-wrapper">
                    <Tooltip
                        tooltipText={getSuperAdminTooltipText(item)}
                        size={"md"}
                        place={"right"}
                    >
                        <div className={`mfa-base ${item.superAdmin ? "enabled" : "disabled"}`} />
                    </Tooltip>
                </div>
            ),
            mfa: (
                <div className="mfa-base-wrapper">
                    <Tooltip tooltipText={getMfaTooltipText(item)} size={"md"} place={"right"}>
                        <div className={`mfa-base ${item.mfa ? "enabled" : "disabled"}`} />
                    </Tooltip>
                </div>
            ),
            paye: (
                <div className="mfa-base-wrapper">
                    <Tooltip tooltipText={getPAYETooltipText(item)} size={"md"} place={"right"}>
                        <div className={`mfa-base ${item.paye ? "enabled" : "disabled"}`} />
                    </Tooltip>
                </div>
            ),
            actions: (
                <div className="row ml-0 ml-0">
                    <div className="icon-tooltip-wrapper-md mr-1">
                        <Tooltip tooltipText={"Edit Staff Member"} size={"md"} place={"left"}>
                            <Icon
                                rootElement={"Div"}
                                icon={IconType.Edit}
                                size={"Medium"}
                                className="icon-dark"
                                onClick={() => {
                                    const path = `${routeNames.editStaff.path}/${item.username}`;
                                    history.push({pathname: path, search});
                                }}
                            />
                        </Tooltip>
                    </div>
                    <ResetPasswordButton {...item} />
                    <DeleteStaffMemberButton {...item} />
                    <div className="icon-tooltip-wrapper-md mr-1">
                        <Tooltip
                            tooltipText={"Edit Annual Leave Configurations"}
                            size={"md"}
                            place={"left"}
                        >
                            <Icon
                                rootElement={"Div"}
                                icon={IconType.Cog}
                                size={"Medium"}
                                className="icon-dark"
                                onClick={() => {
                                    const path = `${routeNames.absenceConfiguration.path}/${item.username}`;
                                    history.push({pathname: path, search});
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
            )
        };
    });
}

function getClinicalGrade(grade: ClinicalGrade): string {
    switch (grade) {
        case ClinicalGrade.EAC:
            return "Emergency Ambulance Crew";
        default:
            return getUiFriendlyText(grade);
    }
}

function getMfaTooltipText(user: UserData) {
    const fullName = `${user.firstName} ${user.lastName}`;
    const enabledText = "has Multi Factor Authentication enabled";
    const disabledText = "has Multi Factor Authentication disabled";

    return `${fullName} ${user.mfa ? enabledText : disabledText}`;
}

function getSuperAdminTooltipText(user: UserData) {
    const fullName = `${user.firstName} ${user.lastName}`;
    const enabledText = "is a Super Admin";
    const disabledText = "isn't a Super Admin";

    return `${fullName} ${user.mfa ? enabledText : disabledText}`;
}

function getPAYETooltipText(user: UserData) {
    const fullName = `${user.firstName} ${user.lastName}`;
    const enabledText = "is PAYE";
    const disabledText = "is not PAYE";

    return `${fullName} ${user.paye ? enabledText : disabledText}`;
}

function getFilteredStaffTableResults(
    users: UserData[],
    {clinicalGrade, region, nameTelephone}: StaffDatabaseRequest,
    history: any,
    search: string
): TableRow[] {
    const filteredByNameTelephone = users.filter((user: UserData) => {
        const fullName = getFullName(user);
        const number = convertInternationalNumberToUk(user.phoneNumber || "");
        return (
            fullName.includes(nameTelephone?.toLowerCase() || "") ||
            number.includes(nameTelephone?.toLowerCase() || "")
        );
    });

    const filteredResults = nameTelephone?.length ? filteredByNameTelephone : users;

    const regionFilter = region
        ? filteredResults.filter((user) => user.regions?.includes(region))
        : filteredResults;

    switch (clinicalGrade) {
        case uiFilterFriendlyClinicalGrades.All:
            return staffListToTableRow(regionFilter, history, search);
        default:
            return staffListToTableRow(
                filterByClinicalGrade(
                    regionFilter,
                    getClinicalGradeFromString(clinicalGrade || "")
                ),
                history,
                search
            );
    }
}

function getFullName(user: UserData): string {
    return `${user.firstName} ${user.lastName}`.toLowerCase();
}
function filterByClinicalGrade(users: UserData[], clinicalGrade: ClinicalGrade): UserData[] {
    return users.filter((user) => user.clinicalGrade === clinicalGrade);
}
