import {TopBar, TopBarItem} from "../../Header/Helpers/topbarHelpers";
import {CurrentSystemDetails, McConfig} from "../../../../../../McConfigPlugin";
import {NavigationItem} from "../../../../../../store/navigation/navigationSlice";
import {StaffAccessLevel, UserData} from "../../../../../../api/staff/code";
import {getUserAccessLevel} from "../../../../../../utils/userDataUtils";

export interface TabsProps<T> {
    tabs: T[];
    currentTab: T;
}

export interface SidebarTabsProps {
    navigationItems: NavigationItem[];
    externalNavigation: TopBar;
    systemDetails: CurrentSystemDetails;
}

export interface SidebarItemContainerProps {
    id: number;
    name: string;
    internalNavigationItems: NavigationItem[];
    externalNavigationItems: TopBarItem[];
}

export function getSidebarTabs(
    props: SidebarTabsProps,
    user: UserData,
    config: McConfig
): SidebarItemContainerProps[] {
    const tabs: SidebarItemContainerProps[] = [];
    tabs.push({
        id: 1,
        name: "Medicare",
        externalNavigationItems: props.externalNavigation.items,
        internalNavigationItems: []
    });
    const internalName = getInternalName(props, user, config);
    tabs.push({
        id: 2,
        name: internalName,
        externalNavigationItems: [],
        internalNavigationItems: props.navigationItems
    });

    return tabs;
}

function getInternalName(props: SidebarTabsProps, user: UserData, config: McConfig) {
    const systemAccessLevel = getUserAccessLevel(user, config);
    switch (systemAccessLevel) {
        case StaffAccessLevel.Staff:
        case StaffAccessLevel.DutyManager:
            return props.systemDetails.elongatedStaffName;
        case StaffAccessLevel.SystemAdministrator:
            return props.systemDetails.elongatedAdminName;
    }
}
